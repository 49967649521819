import React from 'react';
import {Descriptions} from 'antd';



const ServiceDetail = ({selectedService}) => {

    const items = [
        {
          key: '1',
          label: 'รหัสสินค้า : ',
          children: selectedService.service_id,
        },
        {
          key: '2',
          label: 'ชื่อสินค้า : ',
          children: selectedService.service_name,
        },
        {
            key: '3',
            label: 'รายละเอียดสินค้า : ',
            children: selectedService.service_detail,
          },
        {
          key: '4',
          label: 'ราคาสินค้า : ',
          children: selectedService.service_price,
        },
        {
            key: '4',
            label: 'ประเภทสินค้า : ',
            children: selectedService.service_type === 1 ? 'บริการ' : selectedService.service_type === 2 ? 'คอร์ส': null,
        },
      ];


  return (
    <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[1rem] my-10 rounded-3xl' >
    <div className='p-[24px] bg-white shadow-2xl rounded-3xl'>
        <Descriptions
        title="ข้อมูลบริการ"
        bordered
        column={{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        items={items}
    />
    </div>
    </div>
  )
}

export default ServiceDetail