import React,{useEffect,useState} from 'react'

import ServiceList from '../component/ServiceList';
import ServiceDetail from '../component/ServiceDetail';

import axios from 'axios';
import Cookies from 'js-cookie';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import serviceLogo from '../asset/Logo/service.png'

const Service = () => {
  
  const [isLoading, setLoading] = useState(true);
  const [Services, setListOfService] = useState([]);
  const [selectedService, setSelectedService] = useState({})
  const [departmentOptions ,setDepartmentOptions] = useState();
  const [courseItem , setCourseItem] = useState();

  const fetchdata = async () =>{
    const service = await axios.get(process.env.REACT_APP_API_URL + `/course/service`, { headers:{accessToken: Cookies.get('accessToken'),},})
    const courseItem = await axios.get(process.env.REACT_APP_API_URL + `/course/courseItem`, { headers:{accessToken: Cookies.get('accessToken'),},})
    setCourseItem(courseItem.data)
    setListOfService(service.data)
  }

  useEffect(() => {
    const fetchDepartment =  async() =>
    {
      const department = await axios.get(process.env.REACT_APP_API_URL+ '/patient/clinic', { headers:{accessToken: Cookies.get('accessToken'),},})
      const formatdata = department.data.map(item=>({
        value:item.id, label:item.department
      }))
      setDepartmentOptions(formatdata)
    }
    fetchdata()
    fetchDepartment();
    setLoading(false)
  }, []);

  if (isLoading){
    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
    <CircularProgress color="inherit" />
  </Backdrop>
   ;
}


  return (
    <>
    <div className='flex items-end justify-center mt-2'>
      <img src={serviceLogo} alt='departmentLogo' className='w-[5rem]'/>
      <h5 className='text-[3rem] !mb-[0] '>รายชื่อบริการ</h5>
    </div>
    <div style={{paddingTop: "10px"}}>
    <ServiceDetail selectedService={selectedService}/>
    <ServiceList setLoading={setLoading} Service={Services} setListOfService={setListOfService} setSelecteService={setSelectedService} departmentOptions={departmentOptions} fetchdata={fetchdata} courseItem={courseItem}/>
    </div>
    </>
  )
}

export default Service