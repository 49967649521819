import React ,{useState, useEffect} from 'react'
import './createCustomer.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { Button, Form, Input,Card,Select } from 'antd';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";
const Register = () => {
  const [isLoading, setLoading] = useState(true);
  const [list_of_clinics, setListOfClinics ] = useState([]);
  const history = useNavigate();


  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL+`/patient/clinic`, { headers:{accessToken: Cookies.get('accessToken'),},}).then((response)=>{
      setListOfClinics(response.data);
      setLoading(false)
    })
  },[])
  const onSubmit = (data) => {
    setLoading(true);
    const newData = {...data ,role:'employee'}
    axios.post(process.env.REACT_APP_API_URL+'/auth', newData , { headers:{accessToken: Cookies.get('accessToken'),},})
      .then((response) => {
        if(response.data.error) {
          alert(response.data.error);
        } else {
          console.log('Successful register');
          history('/login');
        }
      })
      .catch((error) => {
        alert('Error registering:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
            <div className=' flex flex-1  w-full  justify-center items-center'>
            <div className='md:p-[1rem] mt-[10rem] md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl ' >
            <Card className='rounded-2xl shadow-lg'>
            <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 1000,
          }}
          initialValues={{
            remember: false,
          }}
          onFinish={onSubmit}
          autoComplete="off"
          size='large'
        >
          <Form.Item
            label="ชื่อผู้ใช้"
            name="username"
            
            rules={[
              {
                required: true,
                message: 'กรุณาการชื่อผู้ใช้',
              },
              {
                min: 6,
                message: 'Username ต้องมีความยาวอย่างน้อย6ตัวอักษร',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="รหัสผ่าน"
            name="password"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกรหัสผ่าน',
              },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                message: 'รหัสผ่านต้องมีอย่างน้อย 8 ตัว, รววมตัวอักษรภาษาอังกฤษเเละตัวเลข',
              },
              {
                validator: (rule, value) => {
                  if (value && value.toLowerCase().includes('select') || value.toLowerCase().includes('drop table')) {
                    return Promise.reject('Password cannot contain SQL keywords');
                  }
                  return Promise.resolve();
                },
              }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="ยืนยันรหัสผ่าน"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'กรุณากรอกรหัสผ่าน',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('รหัสผ่านไม่ตรงกัน');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="แผนก"
            name="department_id"
            rules={[
              {
                required: true,
                message: 'กรุณาเลือกแผนก',
              },
            ]}
          >
            <Select placeholder="เลือกแผนก">
              {list_of_clinics.map((value, key) => (
                <Select.Option key={key} value={value.id}>{value.department}</Select.Option>
              ))}
            </Select>
          </Form.Item>

            <div className='flex flex-1  justify-center'>
          <Form.Item
          >
            <Button type="primary" htmlType="submit" className='bg-[#7877FF]'>
              เพิ่มบัญชี
            </Button>
          </Form.Item>
          </div>
        </Form>
        </Card>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
        <CircularProgress color="inherit" />
        </Backdrop>
        </div>
        </div>
  )
}

export default Register