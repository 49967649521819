import React, { useEffect, useState ,useRef,useContext } from 'react';
import { Table ,Button, Modal , DatePicker ,Form, Radio,message} from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import {css} from '@emotion/css'
import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../helpers/AuthContex';
axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";

const ComissionPicker = () => {

    const{authState} = useContext(AuthContext)
    const [monthData, setMonthData] = useState()
    const [isLoading, setLoading] = useState(true);
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    let nv = useNavigate();


    const [form] = Form.useForm();



    useEffect(() => {
        const fetchdata = async () => {
          const response = await axios.get(process.env.REACT_APP_API_URL + `/comission/get-comission-month/byDepartmentId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},});
          setMonthData(response.data);
          setLoading(false)
        }
        fetchdata();
      }, []);


  const handleComissionClick = (month) =>
  {
    nv(`/comission/${month}`);
  }
  const handleComissionHandFeeClick = (month) =>
  {
    nv(`/comission/hand-fee/${month}`);
  }

  const handelDatePickerOpen =() =>
  {
    setIsDatePickerModalOpen(true)
  }
      
  const handelDatePickerCancle =()=>
  { 
    setIsDatePickerModalOpen(false)
    setEndDate();
    setStartDate();
    form.resetFields();
  }

  const handleDateSubmit = ()=>
  {
    const data = form.getFieldsValue();
    const startDate = `${data.startDate.$D}-${data.startDate.$M+1}-${data.startDate.$y+543}`
    const endDate = `${data.endDate.$D}-${data.endDate.$M+1}-${data.endDate.$y+543}`
    if(data.comissionType === 'comission')
    {
      nv(`/comission/byRange/${startDate}/${endDate}`);
    }
    else
    {
      nv(`/comission/hand-fee/byRange/${startDate}/${endDate}`);
    }
    
  }

  const columns = [
    {
      title: 'เดือน',
      dataIndex: 'month',
      key: 'month',
      render: (text, record) => {
        const [month, year] = record.month.split('-');
        const formattedMonth = new Date(`${year-543}-${month}-01`).toLocaleDateString('th-TH', {
          month: 'long',
          year: 'numeric',
        });
        return `${formattedMonth}`;
      },
    },
    {
        title: 'จัดการ',
        dataIndex: 'action',
        key:'action',
        width:'20%',
        render: (text, record) => {
        return(
            <div className='flex'>
            <Button onClick={() => handleComissionHandFeeClick(record.month)}>สรุปค่ามือ</Button>
            <Button onClick={() => handleComissionClick(record.month)}>สรุปค่าแนะนำ</Button>
            </div>
        )
        },
    }

  ];

  const tableCSS = css({
    backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse'
    },
    '& thead > tr': {
      borderWidth: '1px',
      borderColor: 'black',
      borderStyle: 'solid',
      backgroundColor: "blue"
    },
    '.ant-table-thead .ant-table-cell':
    {
      backgroundColor: '#00BA9E',
      color: 'white',
      borderRadius: 'unset !important'
    }
    
  });

  if (isLoading)
    {
      return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
      <CircularProgress color="inherit" />
      </Backdrop>
     ;
    }

  return (
    <>
      <div className='flex items-end justify-center mt-2'>
            <h5 className='md:text-[3rem] text-[2rem] mb-[10px] '>ค่ามือเเละค่าแนะนำ</h5>
      </div>
      <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl mb-10' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl ${(monthData?.length === 0 ) ? 'pb-[24px]' : null}`}>
        <Table columns={columns} dataSource={monthData}  rowKey="month" size="small"  className={tableCSS} pagination={true}/>
        </div>
      </div>

      <Modal  title="เลือกช่วงวันที่" open={isDatePickerModalOpen} onCancel={handelDatePickerCancle} footer={false}>
        

        <Form
        name="basic"
        form={form}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        onFinish={handleDateSubmit}
      >
        <div className='flex flex-col gap-2 justify-center items-center'>
        <Form.Item 
          label="ประเภท"
          name="comissionType"
          rules={[
            {
              required: true,
              message: 'กรุณาเลือกประเภท',
            },
          ]}
        >
          <Radio.Group>
            <Radio value="comissionHandFee"> ค่ามือ </Radio>
            <Radio value="comission"> ค่าแนะนำ </Radio>
          </Radio.Group>
        </Form.Item>

        <div className='flex gap-10'>
        <Form.Item label='วันที่เริ่มต้น' name="startDate"
          rules={[
            {
              required: true,
              message: 'กรุณาเลือกช่วงวันที่',
            },
          ]}
        >
          <DatePicker onChange={()=>{setStartDate(); form.setFieldValue("endDate",null)}} />
        </Form.Item>

        <Form.Item label='วันที่สิ้นสุด' name="endDate"
          rules={[
            {
              required: true,
              message: 'กรุณาเลือกช่วงวันที่',
            },
          ]}
        >
          <DatePicker 
              onChange={(date) => {
                if (date < startDate) {
                  setEndDate(null);
                  form.setFieldsValue({ endDate: null });
                  message.error('ไม่สามารถเลือกวันสิ้นสุดก่อนวันเริ่มต้นได้');
                } else {
                  setEndDate(date);
                }
              }}
              value={endDate}
            />
        </Form.Item>
        </div>
        </div>

        <div className='flex gap-2 justify-center'>
            <Form.Item
            >
              <Button htmlType="submit" >
                ตกลง
              </Button>
              <Button onClick={handelDatePickerCancle} >
                ยกเลิก
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Button className='bg-white' onClick={handelDatePickerOpen}>เลือกช่วงวันที่</Button>
    </>
  );
};

export default ComissionPicker;
