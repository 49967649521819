import './App.css';
import '../src/component/Schedule.css'
import { BrowserRouter as Router, Route, Routes, Link,Navigate, useNavigate} from 'react-router-dom'
import { Select,Drawer} from 'antd'

import Home from "./pages/home";
import Patient from "./pages/patient";
import Login from './pages/login'
import Register from './pages/register'
import PageNotFound from './pages/PageNotFound';
import GuestRoute from './utils/GuestRoute';
import PatientList from './pages/patientlist';
import Adminpage from './pages/adminpage';
import MainSchedule from './pages/MainSchedule';
import Employee from './pages/employee';
import Dashboard from './pages/dashboard';
import Department from './pages/department';
import Service from './pages/service';
import Bills from './pages/bills'
import Comission from './pages/comission';
import ComissionByRange from './pages/comissionByRange';
import ComissionPicker from './pages/comissionPicker';
import ComissionHandFee from './pages/comissionHandFee';
import ComissionHandFeeByRange from './pages/comissionHandFeeByRange';
import Othersetting from './pages/othersetting'

import MyDocument from './pages/pdf';

import axios from 'axios'
import { AuthContext } from './helpers/AuthContex'
import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import PrivateRoutes from './utils/PrivateRoute';
import AdminRoutes from './utils/AdminRoute';

import FullscreenButton from './utils/Fullscreen';
import TrustmeLog from './asset/logo_auto.png'
import TrustmeLog2 from './asset/logo_auto2.png'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';




axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";


function App() {
  const [authState, setAuthState] = useState({username: "", id:0 , status: false, role:"", department_id:""});
  const [isLoading, setLoading] = useState(true);
  const [departmentOption, setDepartmentOption] = useState([{value:0,label:'ทั้งหมด'}])
  const [departmentlabel,setdepartmentlabel] = useState('');
  const [clinic, setclinic] = useState([])
  const [isDrawerOpen ,setIsDrawerOpen] = useState(false);

  const [departmentLogo,setDepartmentLogo] = useState()

  const [menuClass, setMenuClass] = useState('dropdown_menu');
  let nv = useNavigate();


  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL+'/auth/validate',).then((response)=>{
      if(response.data.error)
      {
         setAuthState({...authState, status: false});
      }
      else
      {
        setAuthState({username: response.data.username , id: response.data.id , status: true , role: response.data.role , department_id:response.data.department_id})
        axios.get(process.env.REACT_APP_API_URL+'/patient/clinic',{ headers:{
          accessToken: Cookies.get('accessToken'),
        }}).then((dresponse)=>{
          const department = dresponse.data.map((item)=>({value:item.id,label:item.department}))
          setDepartmentOption(department)
          setDepartmentOption(prevOptions => [...prevOptions, {value:0,label:'ทั้งหมด'}]);
          setclinic(dresponse.data)
          if(response.data.department_id === 0 )
          {
            setdepartmentlabel('ทั้งหมด') 
            setDepartmentLogo(TrustmeLog)
          }
          else
          {
            const currentDepartment = dresponse.data.find(item => item.id === response.data.department_id)
            setdepartmentlabel(currentDepartment.department)
            setDepartmentLogo(currentDepartment.clinic_type === 1 ? TrustmeLog : TrustmeLog2)
          }  
        })
      };
      
    }).then(()=>{
      setLoading(false)
      
    })
    ;
  },[])

  const appSetup = async (cookie) => {
    try {
      const dresponse = await axios.get(process.env.REACT_APP_API_URL + '/patient/clinic', {
        headers: {
          accessToken: cookie,
        }
      });
  
      const department = dresponse.data.map((item) => ({ value: item.id, label: item.department }));
      setDepartmentOption(department);
      setDepartmentOption(prevOptions => [...prevOptions, { value: 0, label: 'ทั้งหมด' }]);
      setclinic(dresponse.data);
  
      if (authState.status === false) {
        // Do something when authState.status is false
      } else {
        if (authState.data.department_id === 0) {
          setdepartmentlabel('ทั้งหมด');
          setDepartmentLogo(TrustmeLog);
        } else {
          const selectedDepartment = dresponse.data.find(item => item.id === authState.data.department_id);
          setdepartmentlabel(selectedDepartment.department);
          setDepartmentLogo(selectedDepartment.clinic_type === 1 ? TrustmeLog : TrustmeLog2);
        }
      }
  
      // Return the response
      return dresponse;
    } catch (error) {
      // Handle errors here
      console.error("Error in appSetup:", error);
      throw error; // You may want to handle or rethrow the error depending on your needs
    }
  };
  


  const handleSelect =(value)=>
  {
    setAuthState({...authState , department_id:value})
    if(value === 0 )
    {
      setdepartmentlabel('ทั้งหมด') 
      setDepartmentLogo(TrustmeLog)
    }
    else
    {
      setdepartmentlabel(clinic.find(item => item.id === value).department)
      setDepartmentLogo(clinic.find(item => item.id === value).clinic_type === 1 ? TrustmeLog : TrustmeLog2)
    }
    nv('/')
  }

  const  logout = () =>{
    Cookies.remove("accessToken")
    setAuthState({username: "", id:0 , status: false})
    toggleMenu();
    onClose()
    setDepartmentLogo(null);
  }

  const toggleMenu = () => {
    setMenuClass(menuClass === 'dropdown_menu' ? 'dropdown_menu open' : 'dropdown_menu');
  };

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };
  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const toggleDrawer = ()=>
  {
    setIsDrawerOpen(!isDrawerOpen);
  }


  if (isLoading){
    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
    <CircularProgress color="inherit" />
  </Backdrop>
   ;
}


  return (
    <div className='App'>
      <AuthContext.Provider value={{ authState, setAuthState, departmentlabel , setdepartmentlabel,departmentLogo,setDepartmentLogo ,clinic , appSetup  }}>
          <div className="nav">
            <div className="navContainer">
              <div className='left-item'>
              {departmentLogo && <img src={departmentLogo} alt="Trustme Clinic" className='  w-14 mx-5'/>}
                <Link to="/" className='navText'>Home</Link>
                
                <FullscreenButton />
                {!authState.status ? (
                  <>
                    <Link  to="/login" className='navText'>Login</Link>
                  </>
                ) : null}
                {authState.role === 'admin' ? (
                  <>
                    สาขา  <Select className='w-[10rem]' options={departmentOption} onSelect={handleSelect} defaultValue={0}/>
                  </>
                ) : null}
              </div>
              <div className='right-item'>   
              {authState.status ? (
                <>
                  <DensityMediumIcon onClick={showDrawer} className="xx"/>
                  <div className='navText_right username'>{authState.username}</div>
                  <div onClick={logout} className='navText_right'>Logout</div>
                </>
              ):<DensityMediumIcon onClick={showDrawer} className="xx"/>}
              </div>   
            </div>
            <Drawer  placement="right" onClose={onClose} open={isDrawerOpen}>
                <div className='flex flex-col gap-5 justify-center flex-1'>
                    <Link onClick={toggleDrawer} to="/" className='text-black text-xl font-bold text-center border-b-2' >Home</Link>
                    {!authState.status ? (
                      <>
                        <Link onClick={toggleDrawer} to="/login" className='text-black text-xl font-bold text-center border-b-2'>Login</Link>
                      </>
                    ) : (
                    <>
                      <div className='text-black text-xl font-bold text-center border-b-2'>{authState.username}</div>
                      <Link  onClick={logout} className='text-black text-xl font-bold text-center border-b-2 '>Logout</Link>
                    </>
                  )}
                </div>
              </Drawer>
            
          </div >
          <Routes>
            <Route path="/login" element={<GuestRoute token={authState.status} />}>
                <Route index element={<Login />} />
              </Route>
            <Route path="/" element={<PrivateRoutes token={authState.status} />}>
              <Route index element={<Dashboard />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/patient/:id/:department_id" element={<Patient />} />
              <Route path="/patientlist" element={<PatientList />} />
              <Route path="/scheduler" element={<MainSchedule />} />
              <Route path="/pdf" element={<MyDocument />} />
              <Route path='/bill' element={<Bills/>}/>
              <Route path='/comission/:id' element = {<Comission/>} />
              <Route path='/comission/byRange/:startDate/:endDate' element = {<ComissionByRange/>} />
              <Route path='/comission/hand-fee/:id' element = {<ComissionHandFee/>}/>
              <Route path='/comission/hand-fee/byRange/:startDate/:endDate' element = {<ComissionHandFeeByRange/>}/>
              <Route path='/comission' element = {<ComissionPicker/>} />
              <Route path="/" element={<AdminRoutes token={authState.role} />}>
                <Route path="/admin" element={<Adminpage />} />
                <Route path="/admin/employee" element={<Employee />} />
                <Route path="/admin/department" element={<Department />} />
                <Route path="/admin/service" element={<Service />} />
                <Route path="/admin/others" element={<Othersetting/>} />
                <Route path="/admin/register" element={<Register />} />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} exact />
          </Routes>
      </AuthContext.Provider>
      
    </div>
  )
}

export default App;
