import React, { useEffect,  useState,useRef} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Button, Input, Space, Table ,Form, Modal,Select,InputNumber} from 'antd'

import { SearchOutlined } from '@ant-design/icons';
import {css} from '@emotion/css'


const { TextArea } = Input;






const SubmitButton = ({ form, handleModalCancel, setListOfEmployee, selectedRow ,fetchdata , mode ,setLoading }) => {
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);

  const handleClick = async () => {
    setLoading(true)
    const data = {
      ...values,
      courseItem: selectedRow || null
    };
  
    const url = mode === 'create' ? '/course/service-add' : '/course/service-update';
    const method = mode === 'create' ? axios.post : axios.put;
  
    try {
      const response = await method(process.env.REACT_APP_API_URL + url, data ,{ headers:{accessToken: Cookies.get('accessToken'),}});
      fetchdata();
      handleModalCancel();
      setLoading(false)
    } catch (error) {
      alert('something wrong')
      setLoading(false)
    }
  };

  const validCourse =  () =>
  {
    
    if(values?.service_type === 2)
    {
      if(selectedRow?.length > 0)
        {
          return !selectedRow.some(item => item.service_times < 1);
        }
        else
        {
          return false
        }
    }
    else
    {
      return true
    }
  }

  React.useEffect(() => {
    if(validCourse()) {
      form.validateFields({ validateOnly: true,})
        .then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          },
        );
    } else {
      setSubmittable(false);
    }
  }, [values, selectedRow]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleClick} className='bg-[#1677FF]'>
       ตกลง
    </Button>
  );
};




const ServiceList = ({setLoading, setListOfService, Service, setSelecteService , departmentOptions , fetchdata , courseItem}) => {


      const [searchText, setSearchText] = useState('');
      const [searchedColumn, setSearchedColumn] = useState('');
      const searchInput = useRef(null);
      const [isModalopen, setIsmodalOpen] = useState(false)

      const [selecteditem, setSelectedItem] = useState()
      const [selectedDepartmentValue,setSelectedDepartmentValue] = useState()

      const [selectedRowKeys, setSelectedRowKeys] = useState([]);

      const [mode,setMode] = useState();

      const [selectedRow, setSelectedRow] = useState([]);

      const [selectedService, setSelectedService] = useState();
      const [showServices,setShowServices] = useState();
    
      

      const [form] = Form.useForm()

      const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedRow(selectedRows);
        },
      };

      const handleNumberInputChange = (record, value) => {
        setShowServices(showServices => showServices.map(row => row.service_id === record.service_id ? { ...row, service_times: value } : row));
        setSelectedRow(selectedRow => selectedRow?.map(row => row.service_id === record.service_id ? { ...row, service_times: value } : row));
      };

      const handleAddService = () =>
      {
        setIsmodalOpen(true)
        setMode('create')
      }

      const handleEditService = (record) =>
      {
        setIsmodalOpen(true)
        setSelectedService(record.service_id)
        setMode('edit')
        form.setFieldsValue(record)  
        if(record.service_type === 2) {
          const keys = courseItem.filter(item => item.course_id === record.service_id).map(item => item.service_id);
          const data = Service.filter(item => keys.includes(item.service_id));
          const initialValues = data.map(row => ({ ...row, service_times: 1 }));
          setSelectedRowKeys(keys);
          setSelectedRow(initialValues);
        }
        
      }

      useEffect(()=>
      { 
        filerCourseItemOptions(form.getFieldValue('department_id'));

      },[form.getFieldValue('department_id')])

      const handleModalCancel =() =>
      {
        setIsmodalOpen(false)
        setSelectedRow()
        setSelectedItem()
        setSelectedDepartmentValue()
        setMode()
        setShowServices()
        setSelectedService()
        form.resetFields();
      }

      const filerCourseItemOptions = (selectedValue) =>
      {
        if(mode === 'edit')
        {
          const list_of_service = Service.filter(item => item?.department_id === selectedValue && item?.service_type === 1)
          const keys = courseItem.filter(item => item.course_id === selectedService);

          const combinedArray = list_of_service.map(product => {
            const amountObj = keys.find(item => item.service_id === product.service_id);
            const amount = amountObj ? amountObj.service_times : 1;
            return { ...product, service_times:amount };
          });

          setShowServices(combinedArray);
        }
        else
        {
          setShowServices(Service.filter(item => item?.department_id === selectedValue && item?.service_type === 1).map(item=> ({...item,service_times:1})))
        }
        
      }



      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
      const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
      };
      const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`ค้าหา ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
                className='bg-[#7877FF]'
              >
                Search
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1677ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
      });

      const option = [{value:1,label:"บริการ"},{value:2,label:"คอร์ส"}]
    
    
      const columns = [
        {
          title: 'รหัสสินค้า',
          dataIndex: 'service_id',
          key: 'service_id',
          ...getColumnSearchProps('service_id'),
          width: '8%',
          align: 'center'
        },
        {
            title: 'ชื่อสินค้า',
            dataIndex: 'service_name',
            key: 'service_name',
            ...getColumnSearchProps('service_name'),
            width:'20%'
          },
          {
            title: 'รายละเอียดสินค้า',
            dataIndex: 'service_detail',
            key: 'service_detail',
            width:'30%'
          },
          {
            title: 'ราคา/หน่วย',
            dataIndex: 'service_price',
            key: 'service_type',
            width: '8%',
            align: 'center'
          },
    
          {
            title: 'ประเภท',
            dataIndex: 'service_type',
            key: 'service_type',
            render: (service_type) => {
                if (service_type === 1) {
                  return <span>บริการ</span>;
                } else if (service_type === 2) {
                  return <span>คอร์ส</span>;
                }
                return null; 
            },
            width: '8%',
            align: 'center'
          },
          {
            title: 'รหัสสาขา',
            dataIndex: 'department_id',
            key: 'department_id',
            ...getColumnSearchProps('department_id'),
            width: '8%',
            align: 'center'
          },
          {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
            {return (
              <Space>
                  <a href="#/" onClick={()=>handleEditService(record)} className=' text-blue-400 font-bold'>แก้ไข</a>
              </Space>
            )}
            ,
            width: '8%',
            align: 'center'
          },
      ];

      const columns2 = [
        {
          title: 'รหัสสินค้า',
          dataIndex: 'service_id',
          key: 'service_id',
          ...getColumnSearchProps('service_id'),
        },
        {
            title: 'ชื่อสินค้า',
            dataIndex: 'service_name',
            key: 'service_name',
            ...getColumnSearchProps('service_name'),
          },
          {
            title: 'ราคา/หน่วย',
            dataIndex: 'service_price',
            key: 'service_type',
          },
          {
            title: 'จำนวน',
            dataIndex: 'service_times',
            key: 'service_times',
            render: (_, record) => (
              <InputNumber
                min={1}
                max={10}
                value={record.service_times}
                onChange={(value) => handleNumberInputChange(record, value)}
              />
            ),
          },
        ];


      const handleRowClick = (t) => {
        setSelecteService(t)
      }
      const tableCSS = css({
        backgroundColor: 'white',
        '& table': {
          borderCollapse: 'collapse'
        },
        '& thead > tr': {
          borderWidth: '1px',
          borderColor: 'black',
          borderStyle: 'solid',
          backgroundColor: "blue"
        },
        '.ant-table-thead .ant-table-cell':
        {
          backgroundColor: '#00BA9E',
          color: 'white',
          borderRadius: 'unset !important'
        }
        
      });
    
        return (
            <>
            <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl ' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl ${(Service?.length === 0 ) ? 'pb-[24px]' : null}`}>
            <Table
                columns={columns}
                dataSource={Service}
                rowKey="service_id" 
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {handleRowClick(record)}, 
                }}}
                pagination={{ pageSize: 7}}
                size='small'
                className={tableCSS}
                scroll={{x:'max-content'}}
            />
            </div>
            </div>
            <Button className='bg-white m-5' onClick={handleAddService}>เพิ่มบริการ</Button>

            <Modal width={600} title="แก้ไขสินค้า" open={isModalopen}  onCancel={handleModalCancel} footer={false}> 
                        <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                          {mode === 'edit' && 
                            <Form.Item
                            name="service_id"
                            label="รหัสสินค้า"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input readOnly />
                          </Form.Item>
                          }
                          <Form.Item
                              name="service_name"
                              label="ชื่อสินค้า"
                              rules={[
                                {
                                  required: true,
                                  max: 37,
                                  message: 'ชื่อสินค้าต้องมีความยาวไม่เกิน 37 ตัวอักษร'
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          <Form.Item
                            name="service_detail"
                            label="รายละเอียดสินค้า"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <TextArea />
                            </Form.Item>
                            <Form.Item
                              name="service_price"
                              label="ราคา"
                              rules={[
                                {
                                  required: true,
                                  validator: async (_, value) => {
                                    if (!value || (value >= 1 && value <= 99999)) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('ราคาต้องอยู่ในช่วง 1 ถึง 99999'));
                                  },
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>

                          <Form.Item
                            name="service_type"
                            label="ประเภทสินค้า"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                          <Select placeholder="Select" options={option}  onChange={(selectedValue, selectedOption)=>{setSelectedItem(selectedValue)}}/>
                          </Form.Item>

                          <Form.Item
                            name="department_id"
                            label="สาขา"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                          <Select placeholder="Select" options={departmentOptions} onChange={(selectedValue, selectedOption)=>{setSelectedDepartmentValue(selectedValue) ; setSelectedRowKeys();setSelectedRow();}} />
                          </Form.Item>

                          {form.getFieldValue('service_type') === 2 && form.getFieldValue('department_id')? (
                            <div className={showServices?.length > 0 ? 'pb-0' : 'pb-10'}>
                            <Table
                            columns={columns2}
                            dataSource={showServices}
                            rowKey="service_id" 
                            size="small"
                            pagination={{ pageSize: 4}}
                            rowSelection={{
                              type: "checkbox",
                              ...rowSelection,
                            }}
                            className={tableCSS}
                            scroll={{x:0}}
                            />
                            </div>
                            ):(null)}


                          <Form.Item>
                            <Space>
                              <SubmitButton form={form} handleModalCancel={handleModalCancel} setListOfEmployee={setSelecteService} selectedRow={selectedRow} fetchdata={fetchdata} mode={mode} setLoading={setLoading}  />
                              <Button htmlType="reset" onClick={handleModalCancel}>ยกเลิก</Button>
                            </Space>
                          </Form.Item>
                        </Form>


                        
              </Modal>
            </>
        );
        }
    
export default ServiceList