import React, {useState,useRef,useContext} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Button, Input, Space, Table, Modal,Form,Select } from 'antd'

import { SearchOutlined } from '@ant-design/icons';
import {css} from '@emotion/css'
import { AuthContext } from '../helpers/AuthContex';

import './PatientList.css'
import "antd/dist/reset.css";



const SubmitButton = ({ form, handleModalCancel, setListOfEmployee, department_id,setLoading}) => {
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);

  const handleClick = async() =>{
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_API_URL + `/employee`,{...values,status:"active"},{ headers:{accessToken: Cookies.get('accessToken'),}})
    const employee = await axios.get(process.env.REACT_APP_API_URL + `/employee`,{ headers:{accessToken: Cookies.get('accessToken'),}})
    if(department_id !== 0 )
        {
          const filtersEmployee = employee.data.filter(item => item.department_id === department_id )
          setListOfEmployee(filtersEmployee)
        }
        else
        {
          setListOfEmployee(employee.data)
        }
    handleModalCancel();
    setLoading(false)
  }

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleClick} className='bg-[#1677FF]'>
      ตกลง
    </Button>
  );
};

const SubmitButton2 = ({form, setListOfEmployee,handleEditModalCancel,setLoading}) => {
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);

  const handleClick = async() =>{
    setLoading(true)
    const response = await axios.put(process.env.REACT_APP_API_URL + `/employee/${values.id}`,{...values,status:"active"},{ headers:{accessToken: Cookies.get('accessToken'),}})
    const employee = await axios.get(process.env.REACT_APP_API_URL + `/employee`,{ headers:{accessToken: Cookies.get('accessToken'),}})
    setListOfEmployee(employee.data)
    handleEditModalCancel();
    setLoading(false)
    
  }

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleClick} className='bg-[#1677FF]'>
      ตกลง
    </Button>
  );
};



const EmployeeListcom = ({setLoading,setListOfEmployee,employee, setSelectedemployee,clinic }) => {

  const {authState} = useContext(AuthContext)
  const [isModalopen, setIsmodalOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const option = clinic.map(item=> ({value:item.id, label:item.department}))
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const roleOptions = [{value:'ผู้จัดการสาขา',label:'ผู้จัดการสาขา'},{value:'นักกายภาพบำบัด',label:'นักกายภาพบำบัด'},{value:'ผู้ช่วยนักกายภาพบำบัด',label:'ผู้ช่วยนักกายภาพบำบัด'}]

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`ค้าหา ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className='bg-[#7877FF]'
            style={{
              width: 90,
            }}
            type="primary"
          >
            Search
            
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  



  const handleRowEdit = (record) => {
    setIsModalEditOpen(true);
    form2.setFieldsValue({
      id: record.id,
      name: record.name,
      age: record.age,
      role: record.role,
      status: record.status,
      department_id: record.department_id,
      handfee:record.handfee,
    });
  };

  // const handleRowDelete = async (key) =>{
  //   const response = await axios.delete(process.env.REACT_APP_API_URL+`/employee/${key}`,{ headers:{accessToken: Cookies.get('accessToken'),}}) 
  // }

  const columns = [
    {
      title: 'รหัสพนักงาน',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
    },
    {
        title: 'ชื่อพนักงาน',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        title: 'ชื่อเล่น',
        dataIndex: 'nickname',
        key: 'nickname',
        ...getColumnSearchProps('nickname'),
      },
      {
        title: 'ต่ำแหน่ง',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'สถานะ',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'ค่าหัต',
        dataIndex:'handfee',
        key:'handfee',
      },
      {
        title: 'รหัสสาขา',
        dataIndex: 'department_id',
        key: 'department_id',
      },
      {
        title: 'จัดการ',
        dataIndex: 'operation',
        render: (_, record) =>
        {return (
          <Space>
              <a href="#/" onClick={()=>{handleRowEdit(record)}}  className=' text-blue-400 font-bold'>แก้ไข</a>
          </Space>
        )}
        ,
        width:"10%",
        align:"center"
      },
  ];
  

  const test = (t) => {
    setSelectedemployee(t)
  }
  
  const handleModalCancel =() =>
  {
    form.resetFields();
    setIsmodalOpen(false)
  }

  const handleEditModalCancel =() =>{
    setIsModalEditOpen(false)
  }

  const tableCSS = css({
    backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse'
    },
    '& thead > tr': {
      borderWidth: '1px',
      borderColor: 'black',
      borderStyle: 'solid',
      backgroundColor: "blue"
    },
    '.ant-table-thead .ant-table-cell':
    {
      backgroundColor: '#00BA9E',
      color: 'white',
      borderRadius: 'unset !important'
    }
    
  });



    return (
        <>
        <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl ' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl ${(employee?.length === 0 ) ? 'pb-[24px]' : null}`}>
        <Table
            columns={columns}
            dataSource={employee}
            rowKey="id" 
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {test(record)}, 
            }}}
            size="small"
            className={tableCSS}
            scroll={{x:'max-content'}}
        />
        </div>
        </div>
        <Button className='bg-white m-5' onClick={()=>{setIsmodalOpen(true)}}>เพิ่มพนักงาน</Button>



        <Modal width={1000} title="เพิ่มพนักงาน" open={isModalopen}  onCancel={handleModalCancel} footer={false}> 
            <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item
                name="name"
                label="ชื่อพนักงาน"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                  name="age"
                  label="อายุ"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกอายุ',
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: 'อายุต้องอยู่ในช่วง 0-100 ปี',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                name="handfee"
                label="ค่ามือ/หัตถการ"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่ามือ/หัตถการ',
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: 'ค่ามือ/หัตถการต้องอยู่ในช่วง 0-100',
                  },
                ]}
              >
                <Input />
                </Form.Item>
                <Form.Item
                name="nickname"
                label="ชื่อเล่น"
                rules={[
                  {
                    required: true,
                  },
                ]}
                >
                <Input />

                </Form.Item>
                <Form.Item
                name="role"
                label="ตำแหน่ง"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="ตำแหน่ง" options={roleOptions} />
              </Form.Item>
              <Form.Item
                name="department_id"
                label="ประจำสาขา"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="ประจำสาขา" options={option} />
              </Form.Item>


              
              <Form.Item>
                <Space>
                  <SubmitButton form={form} handleModalCancel={handleModalCancel} setListOfEmployee={setListOfEmployee}  department_id={authState.department_id} setLoading={setLoading}/>
                  <Button htmlType="reset" onClick={handleModalCancel}>ยกเลิก</Button>
                </Space>
              </Form.Item>
            </Form>
        </Modal>




        <Modal width={1000} title="แก้ไขพนักงาน" open={isModalEditOpen}  onCancel={handleEditModalCancel} footer={false}> 
            <Form form={form2} name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item
                name="id"  
                label="รหัสพนักงาน" 
                >
              <Input readOnly /> 
              </Form.Item>
              <Form.Item
                name="name"
                label="ชื่อพนักงาน"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                  name="age"
                  label="อายุ"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกอายุ',
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: 'อายุต้องอยู่ในช่วง 0-100 ปี',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                name="handfee"
                label="ค่ามือ/หัตถการ"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกค่าหัต',
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: 'ค่าหัตต้องอยู่ในช่วง 0-100',
                  },
                ]}
              >
                <Input />
                </Form.Item>
                <Form.Item
                name="role"
                label="ตำแหน่ง"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="ตำแหน่ง" options={roleOptions} />
              </Form.Item>
              <Form.Item
                name="department_id"
                label="ประจำสาขา"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Select" options={option} />
              </Form.Item>


              
              <Form.Item>
                <Space>
                  <SubmitButton2 form={form2} handleModalCancel={handleEditModalCancel} setListOfEmployee={setListOfEmployee} handleEditModalCancel={handleEditModalCancel} setLoading={setLoading}/>
                  <Button htmlType="reset" onClick={handleEditModalCancel}>ยกเลิก</Button>
                </Space>
              </Form.Item>
            </Form>
        </Modal>


    


        </>
    );
    }

export default EmployeeListcom