import React, {useEffect, useState, useContext,useRef} from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContext } from '../helpers/AuthContex';
import {Modal , Button, Input, Select,Table,Space,Form,Radio,InputNumber} from 'antd'
import { useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import MyDocument from '../pages/pdf';
import BillSummaryDocument from '../pages/pdf_billsumary'
import BillSummaryMonthDocument from '../pages/pdf_billsumary_month'
import { pdf } from '@react-pdf/renderer';
import {css} from '@emotion/css'
import { SearchOutlined ,PrinterOutlined } from '@ant-design/icons';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';



function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const months = [
      "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
      "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
      "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
  ];
  const month = months[date.getMonth()];
  const year = date.getFullYear() + 543;
  const hour = date.getHours();
  const minute = date.getMinutes();
  const formattedHour = (hour < 10 ? '0' : '') + hour;
  const formattedMinute = (minute < 10 ? '0' : '') + minute;
  const formattedDate = `${day} ${month} ${year} ${formattedHour}:${formattedMinute}`;
  return formattedDate;
}


const Billlist = () => {
    const {id} = useParams()
    const [bill, setBill] = useState([])
    const [billItem, setBillitem] = useState([])
    const [order, setOrders] = useState([])
    const [service, setServices] = useState([])
    const [courseItem, setCourseItem] = useState([])
    const [billshow , setBillshow] = useState([])
    const [billItemshow, setBillitemshow] = useState([])
    const [billdata ,setBilldata] = useState([])
    const [patient_data, setPatient_data] = useState({})
    const {authState} = useContext(AuthContext)
    const [rangeDate,setRangeDate] = useState([])
    const [tempRangeDate,setTempRangeDate] = useState([])
    const [rangeMonth, setRangeMonth] = useState([])
    const [tempRangeMonth,setTempRangeMonth] = useState([])
    const sortOption = [{value:1 ,label:"ทั้งหมด"},{value:2 ,label:"เรียงตามวันที่ มาก > น้อย"},{value:3 ,label:"เรียงตามวันที่ น้อย > มาก"},{value:4 ,label:"สรุปตามช่วงวันที่"},{value:5 ,label:"สรุปตามช่วงเดือน"}]
    const [selectedValue, setSelectedValue] = useState(4)
    const [isModalOpen, setIsModalopen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredBill, setFilteredBill] = useState([]);
    const [filterRangeDate, setFilterRangeDate] = useState([]);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [pdfDataUrl, setPdfDataUrl] = useState();
    const [pdf2DataUrl, setPdf2DataUrl] = useState();
    const [listOfDepartment, setListOfdepartment] = useState();
    const [isLoading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [departmentLabel,setDepartmentLabel]= useState('')

    const [isPaymentEditModalOpen, setIsPaymentEditModalCancle] = useState(false);
    const [isSummaryBillModalOpen, setIsSummaryBillModalOpen] = useState(false);

    

    const [form] = Form.useForm();

    function convertDateFormat(originalDateStr) {
      const originalDate = new Date(originalDateStr);
      const year = originalDate.getFullYear();
      const month = String(originalDate.getMonth() + 1).padStart(2, '0');
      const day = String(originalDate.getDate()).padStart(2, '0');
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    }

    const handleModalCancle = () =>
    {
      setIsModalopen(false)
    }

    const handleModalOpen = async (record,billdata,bill_type,department) =>
    {
      setIsModalopen(true);

        const response =  await axios.get(process.env.REACT_APP_API_URL + `/patient/byId/${record.patient_id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
            setPatient_data(response.data)

        try {
         
          const blob = await pdf(
            <MyDocument order={billdata} patient_data={response.data} type={2} date={formatDate(record.createdAt)} billid={record.id} service={service} courseItem={courseItem} department={department} additional_discount={record.additional_discount} remark={record.remark}/>
        ).toBlob({ quality: 1 });

        
          
          const url = URL.createObjectURL(blob);
          setPdfDataUrl(url.toString())
        } catch (error) {
          console.error('Error generating PDF:', error);
        }

    }

    const handleSummaryBillModalCancle = () =>
    {
      setIsSummaryBillModalOpen(false);
    }

    const handleSummaryBillModalOpen = async() =>
    {
      setIsSummaryBillModalOpen(true)
      try {
         
        const blob = await pdf(
          <BillSummaryDocument data={tempRangeDate} d_label={departmentLabel}/>
      ).toBlob({ quality: 1 });

      
        
        const url = URL.createObjectURL(blob);
        setPdf2DataUrl(url.toString())
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }

    const handleSummaryMonthBillModalOpen = async() =>
    {
      setIsSummaryBillModalOpen(true)
      try {
         
        const blob = await pdf(
          <BillSummaryMonthDocument data={tempRangeMonth} d_label={departmentLabel}/>
      ).toBlob({ quality: 1 });

      
        
        const url = URL.createObjectURL(blob);
        setPdf2DataUrl(url.toString())
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }

    const manageFilterDate = (data) =>
    {
      const dateCounts = new Map();

        data.forEach(item => 
        {
          const createdAt = new Date(item.createdAt);
          const date = createdAt.toISOString().split('T')[0];

          if(dateCounts.has(date)){
            dateCounts.set(date, dateCounts.get(date) + 1);
          }
          else{
            dateCounts.set(date, 1);
          }
        });

      const result = [...dateCounts.entries()].map(([date, amount],index) => ({
          key:index,
          date,
          amount
      }));
        setRangeDate(result)
      
    } 

    const handlePaymentEditModalCancle = () =>
    {
      setIsPaymentEditModalCancle(false);
      form.resetFields();
    }

    const handlePaymentEditModalOpen = (record) =>
    {
      setIsPaymentEditModalCancle(true)
      form.setFieldValue('payment_method', record.payment_method);
      form.setFieldValue('id', record.id);
    }

    const handlePaymetnEditFinish = async()=>
    {
      setLoading(true)
      const data = form.getFieldsValue();
      const response = await axios.put(process.env.REACT_APP_API_URL + '/course/bills/payment-method-edit' ,data ,{ headers:{accessToken: Cookies.get('accessToken'),}})
      await fetchdata();
      handlePaymentEditModalCancle();
    }


    

    function transformData(data, item1 ,item2) {
        const newData = data.map(billItem => {
            let matchingItem1 = null;
            matchingItem1 = item1.find(item => item.id === parseInt(billItem.order_id));
            return {
              key:billItem.id,
              order_id:billItem.order_id,
              item_id:matchingItem1.item_id,
              item_amount: matchingItem1.item_times,
              type: matchingItem1.type,
              item_totalprice:matchingItem1.item_totalprice,
              price_to_paid:billItem.price_to_paid,
              billId:billItem.billId,
              discount_value:billItem.discount_value,
              remain_price:billItem.remain_price,
              employee_id:billItem.employee_id,
              times_used:billItem.times_used,
              inviter_id:matchingItem1.inviter_id,
              employee_list: billItem.employee_list,
              payment_method: matchingItem1.payment_method
            };
        });

        const newData2 = newData.map(billItem =>{
            let matchingItem2 = null;
            matchingItem2 = item2.find(item => item.service_id === billItem.item_id)
            return{
                ...billItem,
                item_name:matchingItem2.service_name,
                item_price:matchingItem2.service_price
            }
        });

        return newData2;
    }


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);

      if(dataIndex ===  'date'){
          const filteredData = selectedKeys[0]? rangeDate.filter((item) =>
        item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase()) 
        ):rangeDate;
        setTempRangeDate(filteredData)
      }
      else if(dataIndex === 'monthYear'){
        const filteredData = selectedKeys[0]? rangeMonth.filter((item) =>
        item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase()) 
        ):rangeMonth;
        setTempRangeMonth(filteredData)
      }

    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`ค้าหา ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              className='bg-[#7877FF]'
              style={{
                width: 90,
              }}
              type="primary"
            >
              Search
              
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
  
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    });
    


    const handleSelect = (value) =>
    {
      function compareDatesAscending(b, a) {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      }

      function compareDatesDescending(a, b) {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      }

      if(value === 2)
      {
        const sortedBill = [...bill].sort(compareDatesDescending);
        setBill(sortedBill);
      }
      else if (value === 3)
      {
        const sortedBill = [...bill].sort(compareDatesAscending);
        setBill(sortedBill);
      }
      else if(value === 1)
      {
        const sortedBill = [...bill].sort(compareDatesAscending);
        setBill(sortedBill);
      }
      setSelectedValue(value)
    }
    const areMonthsEqual = (dateString, monthString) =>{
      const dateParts = dateString.split('/');
      const monthParts = monthString.split('/');
      const dateMonth = dateParts[1];
      const dateYear = dateParts[2];
      const monthMonth = monthParts[0];
      const monthYear = monthParts[1];
      return dateMonth === monthMonth && dateYear === monthYear;
    }
    


    const fetchdata = async ()=> {


      const billsresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/bill`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      let filterbillsresponse = billsresponse.data

      if(authState.department_id !== 0 )
      {
        filterbillsresponse = filterbillsresponse.filter((item) => item.department_id === authState.department_id.toString())
        setBill(filterbillsresponse)
      }
      else
      {
        setBill(billsresponse.data)
      }
      const dateCounts = new Map();

      filterbillsresponse.forEach(item => 
        {
          const createdAt = new Date(item.createdAt);
          const date = convertDateFormat(createdAt);
          
          if(dateCounts.has(date)){
            dateCounts.set(date, dateCounts.get(date) + 1);
          }
          else{
            dateCounts.set(date, 1);
          }
        });

      const result = [...dateCounts.entries()].map(([date, amount],index) => ({
          key:index,
          date,
          amount
      }));


      const monthYearCounts = new Map();

      filterbillsresponse.forEach(item => {
        const createdAt = new Date(item.createdAt);
        const year = createdAt.getFullYear();
        const monthYear = `${(createdAt.getMonth() + 1).toString().padStart(2, '0')}/${year}`;

        if (monthYearCounts.has(monthYear)) {
          monthYearCounts.set(monthYear, monthYearCounts.get(monthYear) + 1);
        } else {
          monthYearCounts.set(monthYear, 1);
        }
      });

      const result2 = [...monthYearCounts.entries()].map(([monthYear, amount], index) => ({
        key: index,
        monthYear,
        amount
      }));


   

      
      

      const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      setBillitem(billitemresponse.data)

      const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      setOrders(list_of_orders.data)

      const services = await axios.get(process.env.REACT_APP_API_URL + `/course/service`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      setServices(services.data)

      const coruseItem = await axios.get(process.env.REACT_APP_API_URL + `/course/courseItem`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      setCourseItem(coruseItem.data)

      const department = await axios.get(process.env.REACT_APP_API_URL + `/patient/clinic`, { headers:{accessToken: Cookies.get('accessToken'),},})
      setListOfdepartment(department.data)

      setDepartmentLabel(department.data.find((item) => item.id === authState.department_id)?.department || 'ทั้งหมด')

      const newData = filterbillsresponse.map(item=> ({...item, key: item.id }))
      setBill(newData)

      const newData2 = transformData(billitemresponse.data,list_of_orders.data,services.data)
      setBillitemshow(newData2)

      setLoading(false)
      const RangeDateData = result.map((item) => {
        const [cash, mobile_banking, card ,totalAmount ,noPay] = calculateAmountPaymentMethod(item, filterbillsresponse);
        return {
          ...item,
          cash: cash,
          mobile_banking: mobile_banking,
          card: card,
          total_amount: totalAmount,
          noPay: noPay
        };
      })

      const RangeMonthData = result2.map((item)=>{
        const [cash, mobile_banking, card ,totalAmount ,noPay] = calculateAmountPaymentMethodForMonth(item, RangeDateData);
        return{
          ...item,
          cash: cash,
          mobile_banking: mobile_banking,
          card: card,
          total_amount: totalAmount,
          noPay: noPay
        }
      })
      
      setRangeMonth(RangeMonthData);
      setTempRangeMonth(RangeMonthData);


      setRangeDate(RangeDateData);
      setTempRangeDate(RangeDateData);
  }


    useEffect(() => {
      
    fetchdata();
    
    }, []);

      

      const expandedRowRender1 = (record) => {

        const filterDate = (filteredBill.length > 0 ? filteredBill : bill).filter((item) => convertDateFormat(item.createdAt) === record.date)
        return <Table columns={columns} dataSource={filterDate} pagination={false} className={CollapsetableCSS}
        expandable={{
          expandedRowRender: (record) => expandedRowRender2(record),
          defaultExpandedRowKeys: ['id'],
        }}
        />;
      };

      const calculateAmountPaymentMethod = (record, data) => {
          const filterDate = data.filter((item) => convertDateFormat(item.createdAt) === record.date);
            let cashCount = 0;
            let mobileCount = 0;
            let cardCount = 0;
            let totalAmount = 0;
            let noPay = 0;
            
            filterDate.forEach((item) => {
              if (item.payment_method === 'เงินสด') {
                cashCount++;
              }else if (item.payment_method === 'โอนธนาคาร'){
                mobileCount++;
              }else if(item.payment_method === 'บัตรเครดิต/เดบิต')
              {
                cardCount++;
              }else if(item.payment_method === 'ไม่มี'){
                noPay++;
              }
              totalAmount += item.totoalvalue;
            });
            return [cashCount, mobileCount, cardCount,totalAmount ,noPay];
      };

      const calculateAmountPaymentMethodForMonth = (record, data) => {
        const filterDate = data.filter((item) => areMonthsEqual(item.date, record.monthYear));
          let cashCount = 0;
          let mobileCount = 0;
          let cardCount = 0;
          let totalAmount = 0;
          let noPay = 0;
          filterDate.forEach((item) => {
            cashCount += item.cash
            mobileCount += item.mobile_banking
            cardCount += item.card
            noPay += item.noPay
            totalAmount += item.total_amount
          });
          return [cashCount, mobileCount, cardCount,totalAmount ,noPay];
    };

      const expandedRowRender2 = (record) => {
        const filteredBillItems = billItemshow.filter((item) => item.billId === record.key);

        const ordercolumn = [
            {
              title: 'Order Id',
              dataIndex: 'order_id',
              key:'order_id'
            },
            {
                title: 'ชื่อ',
                dataIndex: 'item_name',
                key:'item_name'
            },
            {
                title: 'จำนวนครั้ง',
                dataIndex: 'item_amount',
                key:'item_amount',
            },
            {
                title: 'ราคาต่อหน่วย',
                dataIndex: 'item_price',
                key:'item_price'
            },
            {
              title: 'ราคารวม',
              dataIndex: 'item_totalprice',
              key:'item_totalprice'
            },
            {
              title: 'ราคาจ่าย',
              dataIndex: 'price_to_paid',
              key:'price_to_paid'
            },
            {
              title: 'ประเภท',
              dataIndex: 'type',
              key:'type',
              render: (type) => {
                if (type === 1) {
                  return <span>บริการ</span>;
                } else if (type === 2) {
                  return <span>คอร์สต์</span>;
                }
                return null; 
              },
            },
          ]

        return <Table columns={ordercolumn} dataSource={filteredBillItems} pagination={false} className={CollapsetableCSS}/>;
      };

      const expandedRowRender3 = (record) => {
        const filterDate = rangeDate.filter((item) => areMonthsEqual(item.date,record.monthYear))
        return <Table columns={RangeDatecolumns} dataSource={filterDate} pagination={false} className={CollapsetableCSS}
        expandable={{
          expandedRowRender: (record) => expandedRowRender1(record),
          defaultExpandedRowKeys: ['date'],
        }}
        />;
      };

      const generatePDF = async (record,billdata,bill_type, department) => {
        const response =  await axios.get(process.env.REACT_APP_API_URL + `/patient/byId/${record.patient_id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
            setPatient_data(response.data)
        try {
          const blob = await pdf(
                <MyDocument order={billdata} patient_data={response.data} type={2} date={formatDate(record.createdAt)} billid={record.id} service={service} courseItem={courseItem} department={department} additional_discount={record.additional_discount} remark={record.remark}/>
            ).toBlob({ quality: 1 });
          
          const url = URL.createObjectURL(blob);
          
          const a = document.createElement('a');
          a.href = url;
          a.download = `bill_${record.id}.pdf`; // Set the desired filename
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          
          // Remove the temporary anchor element
          document.body.removeChild(a);
          
          // Clean up the URL object
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };



      const tableCSS = css({
        backgroundColor: 'white',
        '& table': {
          borderCollapse: 'collapse'
        },
        '& thead > tr': {
          borderWidth: '1px',
          borderColor: 'black',
          borderStyle: 'solid',
          backgroundColor: "blue"
        },
        '.ant-table-thead .ant-table-cell':
        {
          backgroundColor: '#00BA9E',
          color: 'white',
          borderRadius: 'unset !important'
        }
        
      });

      const CollapsetableCSS = css({
        backgroundColor: 'white',
        '& table': {
          borderCollapse: 'collapse'
        },
        '& thead > tr': {
          borderWidth: '1px',
          borderColor: 'grey',
          borderStyle: 'solid',
          backgroundColor: "blue"
        },
        '.ant-table-thead .ant-table-cell':
        {
          backgroundColor: 'grey',
          color: 'white',
          borderRadius: 'unset !important',
        },
        
      });

      const RangeDatecolumns = [
        {
          title: 'วันที่',
          dataIndex: 'date',
          key: 'date',
          ...getColumnSearchProps('date'),
        },
        {
          title: 'จำนวนรายการ',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'เงินสด',
          dataIndex: 'cash',
          key: 'cash',
          width:'7%',
          align:'center'
          //render: (_, record) => calculateAmountPaymentMethod(record,'เงินสด'), 
        },
        {
          title: 'โอน',
          dataIndex: 'mobile_banking',
          key: 'mobile_banking',
          width:'7%',
          align:'center'
          //render: (_, record) => calculateAmountPaymentMethod(record,'โอนธนาคาร'), 
        },
        {
          title: 'บัตรเครดิต/เดบิต',
          dataIndex: 'card',
          key: 'card',
          width:'7%',
          align:'center'
          //render: (_, record) => calculateAmountPaymentMethod(record,'บัตรเครดิต/เดบิต'), 
        },
        {
          title: 'ไม่มี',
          dataIndex: 'noPay',
          key: 'noPay',
          width:'7%',
          align:'center'
          //render: (_, record) => calculateAmountPaymentMethod(record,'บัตรเครดิต/เดบิต'), 
        },
        {
          title: 'จำนวนยอดขาย',
          dataIndex: 'total_amount',
          key: 'total_amount',
          //render: (_, record) => calculateTotalAmount(record), 
        },
        ];


      const RangeMonthColumns = [
        {
          title: 'เดือน',
          dataIndex: 'monthYear',
          key: 'monthYear',
          ...getColumnSearchProps('monthYear'),
        },
        {
          title: 'จำนวนรายการ',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'เงินสด',
          dataIndex: 'cash',
          key: 'cash',
          width:'7%',
          align:'center'
        },
        {
          title: 'โอน',
          dataIndex: 'mobile_banking',
          key: 'mobile_banking',
          width:'7%',
          align:'center'
        },
        {
          title: 'บัตรเครดิต/เดบิต',
          dataIndex: 'card',
          key: 'card',
          width:'7%',
          align:'center'
        },
        {
          title: 'ไม่มี',
          dataIndex: 'noPay',
          key: 'noPay',
          width:'7%',
          align:'center'
        },
        {
          title: 'จำนวนยอดขาย',
          dataIndex: 'total_amount',
          key: 'total_amount',
        },
        ];




      const columns = [
        {
          title: 'Bill ID',
          dataIndex: 'id',
          key: 'id',
          width:'5%',
          ...getColumnSearchProps('id'),
          
        },
        {
          title: 'รหัสคนไข้',
          dataIndex: 'patient_id',
          key: 'patient_id',
          width:'5%',
          ...getColumnSearchProps('patient_id'),
        },
        {
          title: 'Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          ...getColumnSearchProps('createdAt'),
          render : (createdAt) =>
          {
            return formatDate(createdAt);
          }
        },
        {
          title: 'จำนวนเงินรวม',
          dataIndex: 'totoalvalue',
          key: 'totoalvalue',
          width:'5%',
        },
        {
          title: 'วิธีจ่ายเงิน',
          dataIndex: 'payment_method',
          key: 'payment_method',
          width:'5%',
          render : (_,record) =>
          {
            return(
              <div onClick={()=>{handlePaymentEditModalOpen(record)}} className='text-blue-400 hover:underline hover:cursor-pointer active:text-green-200 select-none duration-75 '>{record.payment_method}</div>
            )
          }
        },
        {
          title: 'ประเภทบิล',
          dataIndex: 'bill_type',
          key: 'bill_type',
          width:'5%',
          align:'center',
          render: (text, record) => {
            return record.bill_type === 1 ? 
            <div>บิลใหม่</div> : 
            <div>ตัดบิล</div>;
          }
        },  
        
        {
          title: 'จัดการ',
          key: 'operation',
          align:'center',
          width: '5%', 
          render: (text, record) => {
            const filteredBillItems = billItemshow.filter((item) => item.billId === record.id);
            const filterDepartment = listOfDepartment?.find((item) => item.id === parseInt(record.department_id))
            const billdata = filteredBillItems.map((item) => ({
              id: item.order_id,
              item_id: item.item_id,
              name: item.item_name,
              amount: item.item_amount,
              price: item.item_price,
              discount: item.discount_value,
              type: item.type,
              totalPrice: item.price_to_paid,
              remain: item.remain_price,
              employee_id: item.employee_id,
              times_used:item.times_used,
              inviter_id:item.inviter_id,
              employee_list: item.employee_list
              
            
          }));
            return(
              <div className='flex'>
              <Button icon={<DownloadOutlined />} onClick={()=>{generatePDF(record,billdata,record.bill_type ,filterDepartment)}}>โหลดPDF</Button>
              <Button icon={<DownloadOutlined />} onClick={()=>{handleModalOpen(record,billdata,record.bill_type ,filterDepartment)}}>เปิดดู PDF</Button>
              </div>
            )
          },
        },
      ];

      


      if (isLoading)
    {
      return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
      <CircularProgress color="inherit" />
      </Backdrop>
     ;
    }

    return (
      <>
        <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl mb-10' >
            <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl ${(bill?.length === 0 ) ? 'pb-[24px]' : null}`}>
            <div className='flex justify-between md:mx-10 mb-3 items-center gap-3'> 
              <div className='flex items-center '>
                เรียงตาม
                <Select className=' md:w-[10rem] w-[8rem]' options={sortOption} defaultValue={4} onSelect={handleSelect} /> 
              </div>
              {
              (selectedValue === 4) &&(
                <div>
                <PrinterOutlined className='p-1 border-[1px] border-grey rounded-md cursor-pointer' onClick={handleSummaryBillModalOpen}/>
              </div>)
              }
              {
              (selectedValue === 5) &&(
                <div>
                <PrinterOutlined className='p-1 border-[1px] border-grey rounded-md cursor-pointer' onClick={handleSummaryMonthBillModalOpen}/>
              </div>)
              }
              
            </div>
  
            <Table
              columns={(selectedValue === 4) ? RangeDatecolumns : (selectedValue === 5)? RangeMonthColumns:columns }
              expandable={{
                expandedRowRender: (record) => (selectedValue === 4) ? expandedRowRender1(record) : (selectedValue === 5) ? expandedRowRender3(record): expandedRowRender2(record),
                defaultExpandedRowKeys: ['id'],
              }}
              dataSource={(selectedValue === 4) ? (rangeDate) : (selectedValue === 5)? rangeMonth : (bill)}
              size={"small"}
              className={tableCSS}
              scroll={{x:'max-content'}}
            />
          </div>
        </div>
  
        <Modal width={1000} title="ออกบิล" open={isModalOpen} onCancel={handleModalCancle} footer={[<Button key="ok" >ตกลง</Button>,<Button key="close" onClick={handleModalCancle} >ปิด</Button>]}>

          <embed src={pdfDataUrl} frameBorder={"0"} width={"100%"} height={window.innerWidth < 786 ? "500px" : "1000px"}/>
  
            
        </Modal>

        <Modal  title="แก้ไข้วิธีชำระเงิน" open={isPaymentEditModalOpen} onCancel={handlePaymentEditModalCancle} footer={false}>
            
            <Form form={form} onFinish={handlePaymetnEditFinish}>
            <Form.Item  style={{ marginBottom: "10px" }}
                      label="รหัสใบเสร็จ"
                      name="id"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาใส่รหัสบิล',
                        },
                      ]}
                    >
                    <InputNumber readOnly={true}></InputNumber>
                    
                    </Form.Item>
                      <Form.Item  style={{ marginBottom: "10px" }}
                      label="วิธีการชำระเงิน"
                      name="payment_method"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาเลือกประเภท',
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="เงินสด"> เงินสด </Radio>
                        <Radio value="โอนธนาคาร"> โอนธนาคาร </Radio>
                        <Radio value="บัตรเครดิต/เดบิต"> บัตรเครดิต/เดบิต </Radio>
                        <Radio value="ไม่มี"> ไม่มี</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item style={{marginBottom:'0px',display:'flex',justifyContent:'center'}}
                    >
              <Button htmlType="submit" >
                ตกลง
              </Button>
              <Button onClick={handlePaymentEditModalCancle} >
                ยกเลิก
              </Button>
            </Form.Item>
            </Form>
        </Modal>

        <Modal width={1000} title="สรุปยอด" open={isSummaryBillModalOpen} onCancel={handleSummaryBillModalCancle} footer={[<Button key="ok" >ตกลง</Button>,<Button key="close" onClick={handleSummaryBillModalCancle} >ปิด</Button>]}>
          <embed src={pdf2DataUrl} frameBorder={"0"} width={"100%"} height={window.innerWidth < 786 ? "500px" : "1000px"}/>
        </Modal>
      </>
    );
}

export default Billlist