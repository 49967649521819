import React, { useEffect, useState, useContext } from 'react';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './home.css';
import Select from "react-select";
import TrustmeLog from '../asset/logo_auto.png'
import Cookies from 'js-cookie';
import { AuthContext } from '../helpers/AuthContex';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Home() {
  const{authState ,setAuthState} = useContext(AuthContext)
  const [isLoading, setLoading] = useState(false);
  const [clinic, setClinic] = useState([])

  const [selectedClinic, setSelectedClinic] = useState("ทั้งหมด");
  const [selectedClinicId, setSelectedClinicId] = useState(authState.department_id);


  const handleClinicSelect = (event) => {
    setLoading(true)
    if(event.target.value == 0)
    {
      setSelectedClinicId(0)
      setAuthState({...authState,department_id:0})
      setSelectedClinic("ทั้งหมด")
      setLoading(false)
    }
    else
    {
      const selectedValue = event.target.value;
      setSelectedClinicId(selectedValue);
      setAuthState({...authState,department_id:selectedValue})
      setSelectedClinic(clinic.find((clinic) => clinic.id == event.target.value).department)
      axios.get(process.env.REACT_APP_API_URL+`/patient/bydp/${event.target.value}`, { headers:{accessToken: Cookies.get('accessToken'),},}).then((response) => {
      setLoading(false)
      setListOfPatients(response.data)})
    }
    
  };

  const MIN_INPUT_LENGTH = 3;

  const customFilterOption = (option, inputValue) => {
    if (inputValue.length>= MIN_INPUT_LENGTH && (option.data.call_data.includes(inputValue.toLowerCase())|| option.data.label.includes(inputValue.toLowerCase()))) {
      return true;
    }
    return false;
  };


  const noOptionsMessage = ({ inputValue }) => {
    if (inputValue.length >= MIN_INPUT_LENGTH) {
      return "ไม่พบผู้ใช้";
    } else {
      return "กรุณาใส่ชื่อ หรือ เบอร์โทร";
    }
  };





  let history = useNavigate();

  const [listOfPatients, setListOfPatients] = useState([]);

  useEffect(() => {
    setLoading(true)
    axios.get(process.env.REACT_APP_API_URL+`/patient/bydp/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},}).then((response) => {
      setListOfPatients(response.data);
      axios.get(process.env.REACT_APP_API_URL+'/patient/clinic').then((response)=>{
        setClinic(response.data)
        if(authState.role === 'admin')
        {

        }
        else
        {
          setSelectedClinic(response.data.find((clinic) => clinic.id == authState.department_id).department)
          setSelectedClinicId(authState.department_id)
        }
      })
    }).finally(()=>{
      setLoading(false)
      
      
    })
  }, []);



  const options = listOfPatients.map((patient) => ({
  value: patient.id,
  label: patient.name,
  call_data: patient.call_data, 
    customLabel: (
      <div>
        <p>{patient.name}</p>
        <p>เบอร์โทร: {patient.call_data}</p>
      </div>
    ),
  }));

  const customFormatOptionLabel = ({ customLabel }) => customLabel

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      history(`/patient/${selectedOption.value}/${selectedClinicId}}`);
    }
  };

  return (
    <div>
      {authState.role === "admin" &&(
        <select id="clinicSelect" value={selectedClinicId} onChange={handleClinicSelect}>
          {clinic.map((cvalue, index) => 
          (
            <option key={index} value={cvalue.id}>{cvalue.department}</option>
          ))
          }
          <option value={0}>ทั้งหมด</option>
        </select>
        )}
      


      <div className="block_home">

        <img src={TrustmeLog} className='mainimg'/>
        <h1>สาขา {selectedClinic}</h1>

        <div className="block_item">
          <Select options={options} onChange={handleSelectChange} filterOption={customFilterOption} noOptionsMessage={noOptionsMessage}  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }} formatOptionLabel={customFormatOptionLabel}/>
        </div>
            <div>
              <Link to={`/scheduler`} className='myButton' >ตารางนัด</Link>
              {selectedClinicId !== 0 &&(<Link to={`/createCustomer/${selectedClinicId}`} className='myButton' >ลูกค้าใหม่</Link>)}
              <Link to={`/patientlist`} className='myButton' >รายชื่อลูกค้า</Link>
            </div>  
             {authState.role === "admin" &&(<Link to={`/admin`} className='myButton' >ตั้งค่า [ADMIN] (In Production)</Link>)}
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
      <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Home;
