import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import Angsana from '../asset/fonts/ANGSA.ttf';
import Logo from '../asset/logo_auto.png';
import Logo2 from '../asset/logo_auto2.png';

import JsBarcode from 'jsbarcode';



Font.register({ family: 'Angsana', src: Angsana });

Font.registerHyphenationCallback(word => [word]);


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontFamily: 'Angsana',
    padding:10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  container: {
    height: 200,
    width: 350,
  },
  header: {
    flexDirection: 'row',
    //border: '1pt solid black',
    marginRight: 10,
    marginTop: 10,
    width:"100%",
    height: 100,
  },
  logo: {
    width: 70,
    height: 70,
  },
  text: {
    textOverflow: 'wrap', // This property enables text wrapping
    fontSize:14,
    marginLeft:10
  },
  textnowrap: {
    fontSize:14,
    marginLeft:10,
    whiteSpace: 'nowrap',

  },


  textorder: {
    textOverflow: 'wrap', // This property enables text wrapping
    fontSize:9,
    paddingHorizontal:'3'
  },

  textorder2: {
    textOverflow: 'hidden', // This property enables text wrapping
    whiteSpace: 'nowrap',
    fontSize: 13.5,
  },
  

  titletext: {
    fontWeight: 'bold',
    textOverflow: 'wrap',
    fontSize:16,
    marginLeft:10
  },
  container1: {
    position: 'absolute', 
    border: '1pt solid black',
    borderRadius: 4,
    bottom: 0,
    right: 0,
    height: "50%",
    width: "30%"
  },

  testText:{
    textAlign:"center",
    fontSize:20
  },
  section1:{
    //border: '1pt solid black',
    flexDirection: 'row',
    marginTop:3
  },

  patietndetail:{
    width:"65%",
    border: '1pt solid black',
    borderRadius: 4,
    height:110,
    marginRight:3
  },

  barcode:{
    width:"35%",
    border: '1pt solid black',
    borderRadius: 4,
    height:110,
  },
  barcodeimg:{
    width:"100%",
    height:"35%",
    paddingLeft:10
  },

  section2:{
    border: '1pt solid black',
    flexDirection: 'row',
    marginTop:3,
    with:"100%",
    minHeight: "45%",
    borderRadius: "4",
  },

  tablecolumn1:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"8%",
    textAlign:"center"
  },

  tablecolumn2:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"40%",
    textAlign:"center"
  },
  tablecolumn2_courseItem:{
    flexDirection:"column",
    textAlign:"left",
    marginLeft: "10px",
    overflow: 'hidden'
  },

  tablecolumn3:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"8%",
    textAlign:"center"
  },

  tablecolumn4:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"11%",
    textAlign:"center"
  },

  tablecolumn5:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"11%",
    textAlign:"center"
  },

  tablecolumn6:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"11%",
    textAlign:"center"
  },
  tablecolumn7:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"11%",
    textAlign:"center"
  },

  tablecolumn8:{
    borderRight: '1pt solid black',
    flexDirection:"column",
    width:"10%",
    textAlign:"center"
  },
  tablecolumn9:{
    flexDirection:"column",
    width:"10%",
    textAlign:"center"
  },

  tablecolumnname:{
    borderBottom:"1pt solid black",
    height:'10%',
    alignItems:'start',
    display: 'flex',
    justifyContent: 'start',
    paddingTop:'4'
  },

  section3:{
    border: '1pt solid black',
    flexDirection: 'column',
    with:"100%",
    height:"27%",
    borderRadius: "4",
  },

  section3_1:{
    borderBottom:"1pt solid black",
    flexDirection: 'row',
    with:"100%",
    height:"55%"
  },

  section3_2:{
    flexDirection: 'row',
    with:"100%",
    height:"45%"
  },

  remark:{
    borderRight: '1pt solid black',
    width:"60%"
  },

  remark1:{
    borderBottom: '1pt solid black',
    height:"85%",
    overflow:'hidden',
    textOverflow:'ellipsis'
  },

  remarktext1:{
    fontSize:10,
    marginLeft:5,
    wordWrap: 'break-word'
  },
  remarktext2:{
    fontSize:10,
    marginLeft:5,
    textAlign:"center"
  },

  remark2:{
    height:"15%"
  },

  totalcost:{
    width:"40%"
  },

  

  totalcost1:{
    borderBottom: '1pt solid black',
    height:"85%"
  },

  totalcost1_item:{
    flexDirection:"row",
    justifyContent:"space-between",
    marginRight:"10"
  },

  totalcosttext1:{
    fontSize:12,
    marginLeft:5,
  },

  totalcosttext2:{
    fontSize:12,
    marginLeft:5,
  },

  approver:
  {
    width:"30%"
    ,justifyContent:"space-evenly"
  },
  paidBy:
  {
    borderRight: '1pt solid black',
    width:"70%"
  },
  paidBytext:
  {
    fontSize:12,
    marginLeft:5,
  }

  
});

const MyDocument = ({order, patient_data, type, date, billid,service,courseItem ,department,additional_discount, remark}) => {

  const totalprice = order.reduce((acc, item) => acc + item.amount * item.price, 0)
  const discount = order.reduce((acc, item) => acc + (item.discount), 0)
  const totalRemainPrice = order.reduce((acc,item) => acc + parseFloat(item.remain), 0)
  const totalPriceToPiad = order.reduce((acc,item) => acc + parseFloat(item.totalPrice), 0)
  const pricewithdiscount = totalprice-discount

  const coursewithName = courseItem.map(item => {
    let matchingItem1 = null;
    matchingItem1 = service.find(mitem => mitem.service_id === item.service_id);
    return {
      ...item,
      service_name: matchingItem1.service_name,
    };
  });




  const thaiNumbers = [
    'ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'
  ];
  
  const thaiDigits = [
    '', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'
  ];
  
  function convertToThaiText(number) {
    if (isNaN(number)) {
      return 'Invalid input';
    }
  
    if (number === 0) {
      return 'ศูนย์';
    }
  
    const parts = number.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] || '';
  
    const integerText = integerPart.split('').map((digit, index) => {
      const digitValue = parseInt(digit);
      if (digitValue === 0) {
        return '';
      }
      return thaiNumbers[digitValue] + thaiDigits[integerPart.length - index - 1];
    }).join('');
  
    const decimalText = decimalPart.split('').map(digit => {
      const digitValue = parseInt(digit);
      return thaiNumbers[digitValue];
    }).join('');
  
    let result = integerText;
    if (integerText !== '') {
      result += 'บาท';
    }
  
    if (decimalText !== '') {
      result += decimalText + 'สตางค์';
    } else {
      result += 'ถ้วน';
    }

    
  
    return result;
  }
  
  const tableData = order.map((orderitem,index)=>
    ({
      index:index+1, 
      name:orderitem.name, 
      amount:orderitem.amount, 
      price:orderitem.price, 
      totalPrice: formatNumber(parseFloat(orderitem.price) * parseFloat(orderitem.amount)) , 
      discount: `${orderitem.discount ? formatNumber((parseFloat(orderitem.discount)*100)/(parseFloat(orderitem.price) * parseFloat(orderitem.amount))) : 0} %`,
      remain: orderitem.remain !== null ? formatNumber(totalRemainPrice) : 0,
      priceToPiad: orderitem.totalPrice ? formatNumber(orderitem.totalPrice): 0,
      inviter_id: orderitem.inviter_id ? parseInt(orderitem.inviter_id) === -1 ? '-': orderitem.inviter_id : 0,
      healthPProviderId: orderitem.employee_list.length > 0 ? orderitem.employee_list.map((item, index) => (index === 0 ?(parseInt(item) === -1 ? '-': item)  : (parseInt(item) === -1 ? ',-': `,${item}`))).join('') : '-',
    }))

    const columnWidths = ['5%', '40%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%'];
  
  function formatNumber(number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  let canvas;

  canvas = document.createElement('canvas');
  JsBarcode(canvas, `INV-${billid}` ,{
    displayValue: false,
    height: 100,
    width: 6
  });
  const barcode = canvas.toDataURL();

  return(

  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={department.clinic_type ===1?Logo:Logo2} />
          <View style={styles.container}>
            <Text style={styles.titletext}>Trustme {department.clinic_type ===1?'Plus':null} คลินิกกายภาพบำบัด (สาขา {department.department} )</Text>
            <Text style={styles.text}>{department.address}  </Text>
            <Text style={styles.text}>(โทร.) {department.telephone}</Text>
            <Text style={styles.text}>ใบอนุญาตสถานพยาบาลเลขที่ : {department.hospital_license}</Text>
            <Text style={styles.text}>เลขประจำตัวผู้เสียภาษี {department.taxpayer_identification_number}</Text>
          </View>

          <View style={styles.container1}>
            <Text style={styles.testText}>ใบเสร็จรับเงิน</Text>
            <Text style={styles.testText}>RECEIPT</Text>
          </View>
      </View>

      <View style={styles.section1}>
        
          <View style={styles.patietndetail}>
              <Text style={styles.text}>รหัสลูกค้า : {patient_data.id}  เลขประจำตัวผู้เสียภาษี : {patient_data.idd}</Text>
              <Text style={styles.text}>นามลูกค้า {patient_data.name}</Text>
              <Text style={styles.text}>Customer : </Text>
              <Text style={styles.text}>ที่อยู่ : {patient_data.Address}</Text>
              <Text style={styles.text}>Address : </Text>
              <Text style={styles.text}>โทรศัพท์/Phone : {patient_data.call_data}</Text>
              <Text style={styles.text}>แฟกซ์/Fax : </Text>
          </View >

          <View style={styles.barcode} >
              <Text style={styles.text}>เลขที่เอกสาร : INV-{billid}</Text>
              <Image style={styles.barcodeimg} src={barcode}/>
              <Text style={styles.textnowrap}>วันที่ทำรายการ : {date}  </Text>
              <Text style={styles.textnowrap}>กำหนดชำระเงิน : {date}  </Text>
              <Text style={styles.text}>พนักงาน : </Text>
              <Text style={styles.text}>เงื่อนไข : </Text>
          </View>
      </View>
      <View style={styles.section2}>
        <View style={styles.tablecolumn1}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>ลำดับ  </Text>
            <Text style={styles.textorder}>ITEM NO.</Text>
          </View>

          <View>
          {order && order.length > 0 && order.map((orderitem,index) => (
            <>
              <Text key={index} style={styles.textorder2}>{index+1}</Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2}>{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>

        <View style={styles.tablecolumn2}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>ชื่อสินค้า/รายละเอียด</Text>
            <Text style={styles.textorder}>PRODUCT DESCRIPTION</Text>
          </View>  
          <View>
          {order && order.length > 0 &&  order.map((orderitem) => (
            <>
            <View style={styles.tablecolumn2_courseItem}>
              <Text key={orderitem.id} style={styles.textorder2}>{orderitem.name}</Text>
              
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text key={index} style={styles.textorder2}>- {item.service_name}</Text>) : null
                )
              ): null  }
              </View>
              </>
          ))}
          </View>
        </View>

        <View style={styles.tablecolumn3}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>จำนวน  </Text>
            <Text style={styles.textorder}>QUANTITY</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text key={orderitem.id} style={styles.textorder2}>{orderitem.amount}</Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2}>{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>

        {/* <View style={styles.tablecolumn3}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>จำนวนที่ใช้  </Text>
            <Text style={styles.textorder}>USED</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text key={orderitem.id} style={styles.textorder2}>{orderitem.times_used}</Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2}>{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View> */}

        <View style={styles.tablecolumn4}>
          <View style={styles.tablecolumnname}> 
            <Text style={styles.textorder}>ราคา/หน่วย</Text>
            <Text style={styles.textorder}>UNIT PRICE</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text key={orderitem.id} style={styles.textorder2}>{formatNumber(orderitem.price)}</Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2}>{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>
        <View style={styles.tablecolumn4}>
          <View style={styles.tablecolumnname}> 
            <Text style={styles.textorder}>ราคารวม</Text>
            <Text style={styles.textorder}>TOTAL PRICE</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text key={orderitem.id} style={styles.textorder2}>{formatNumber(parseFloat(orderitem.price) * parseFloat(orderitem.amount))}</Text>
              
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2}>{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>

        <View style={styles.tablecolumn5}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>ส่วนลด</Text>
            <Text style={styles.textorder}>DISCOUNT</Text>
          </View>

          <View> 
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text key={orderitem.id} style={styles.textorder2}>{orderitem.discount ? formatNumber((parseFloat(orderitem.discount)*100)/(parseFloat(orderitem.price) * parseFloat(orderitem.amount))) : 0} % </Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2}>{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>
        
        

        <View style={styles.tablecolumn6}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>คงเหลือ</Text>
            <Text style={styles.textorder}>REMAIN PRICE</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text key={orderitem.id} style={styles.textorder2}>{orderitem.remain !== null ? formatNumber(orderitem.remain) : 0}  </Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text  style={styles.textorder2}>{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>
        <View style={styles.tablecolumn7}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>ราคาที่ชำระ   </Text>
            <Text style={styles.textorder}>AMOUNT</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text key={orderitem.id} style={styles.textorder2}>{orderitem.totalPrice ? formatNumber(orderitem.totalPrice): 0}  </Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2} >{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>
        <View style={styles.tablecolumn8}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>รหัสผู้แนะนำ  </Text>
            <Text style={styles.textorder}>INVITER ID</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>

              <Text key={orderitem.id} style={styles.textorder2}>{orderitem.inviter_id ? parseInt(orderitem.inviter_id) === -1 ? '-': orderitem.inviter_id : 0}  </Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2} >{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>
        <View style={styles.tablecolumn9}>
          <View style={styles.tablecolumnname}>
            <Text style={styles.textorder}>รหัสพนักงาน  </Text>
            <Text style={styles.textorder}>Health Provider ID</Text>
          </View>
          <View>
          {order && order.length > 0 && order.map((orderitem) => (
            <>
              <Text style={styles.textorder2}>
              {orderitem.employee_list.length > 0 ? orderitem.employee_list.map((item, index) => (index === 0 ?(parseInt(item) === -1 ? '-': item)  : (parseInt(item) === -1 ? ',-': `,${item}`))).join('') : '-'} 
              </Text>
              {orderitem.type === 2 ? (
                coursewithName.map((item,index) => item.course_id === orderitem.item_id ? 
                (<Text style={styles.textorder2} >{"\n"}</Text>) : null
                )
              ): null  }
            </>
          ))}
          </View>
        </View>

      </View>
      <View style={styles.section3}>

        <View style={styles.section3_1}>

          <View style={styles.remark}>

            <View style={styles.remark1} >
              <Text style={styles.remarktext1}>หมายเหตุ/Remark</Text>
              <Text style={styles.remarktext1}> {remark} </Text>
            </View >

            <View style={styles.remark2}>
              <Text style={styles.remarktext2}>{convertToThaiText(totalPriceToPiad)}</Text>
            </View>

          </View>

          <View style={styles.totalcost}>     

            <View style={styles.totalcost1}>
              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>รวมมูลค่าสินค้า  </Text>
                  <Text style={styles.totalcosttext1}>{formatNumber(totalprice)} บาท</Text>
              </View>

              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>รวมส่วนลดเเต่ละรายการ  </Text>
                  <Text style={styles.totalcosttext1}>{formatNumber(discount-additional_discount)} บาท</Text>
              </View>

              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>ส่วนลดการค้า 0%  </Text>
                  <Text style={styles.totalcosttext1}>0 บาท</Text>
              </View>

              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>ส่วนลดเพิ่มเติม  </Text>
                  <Text style={styles.totalcosttext1}>{formatNumber(additional_discount)} บาท</Text>
              </View>

              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>รวมมูลค่าสินค้าหลังหักส่วนลด  </Text>
                  <Text style={styles.totalcosttext1}>{formatNumber(pricewithdiscount)} บาท</Text>
              </View>
              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>จำนวนเงินที่ชำระ  </Text>
                  <Text style={styles.totalcosttext1}>{formatNumber(totalPriceToPiad)} บาท</Text>
              </View>
              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>รวมมูลค่าสินค้าคงเหลือ  </Text>
                  <Text style={styles.totalcosttext1}>{formatNumber(totalRemainPrice)} บาท</Text>
              </View>
              <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext1}>ภาษีมูลค่าเพิ่ม 0%</Text>
                  <Text style={styles.totalcosttext1}>0 บาท</Text>
              </View>
              
            </View >

            <View style={styles.totalcost1_item}>
                  <Text style={styles.totalcosttext2}>จำนวนเงินรวมทั้งสิ้น </Text>
                  <Text style={styles.totalcosttext1}>{formatNumber(totalPriceToPiad )} บาท</Text>
              </View>

          </View>
        </View>



        <View style={styles.section3_2} >
          <View style={styles.paidBy}>
            <Text style={styles.paidBytext}>ชำระโดย  </Text>
            <Text style={styles.paidBytext}>▢Cash/เงินสด  ▢Check/เช็ค  ▢Mobile Banking/โอนเงิน  ▢Credit Card/บัตรเครดิต</Text>
            <Text style={styles.paidBytext}>เลขที่เช็ค/เลขบัญชี ___________________ ลงวันที่ ____/____/_____</Text>
            <Text style={styles.paidBytext}>ธนาคาร ____________________________ สาขา  _______________</Text>
            <Text style={styles.paidBytext}>กรณีชำระเงินด้วยเช็ค ใบเสร็จรับเงินจะสมบูรณ์เมื่อบริษัทฯ ได้รับเงินตามเช็คเรียบร้อยเเล้วเท่านั้น  </Text>
            <Text style={styles.paidBytext}>โปรดจ่ายเช็คขีดคร่อม A/C PAYEE ONLY Trustme plus คลินิกกายภาพบำบัด  </Text>
          </View>
          <View style={styles.approver}>
            <Text style={styles.paidBytext}>ผู้อนุมัติ   _______________________</Text>
            <Text style={styles.paidBytext}>ผู้จ่ายเงิน  _______________________</Text>
            <Text style={styles.paidBytext}>ผู้รับเงิน   _______________________</Text>
            <Text style={styles.paidBytext}>วันที่      _______________________</Text>
          </View>
        </View>

      </View>

    </Page>
  </Document>
  );
          };

export default MyDocument;
