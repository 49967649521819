import React from 'react';
import { Badge, Descriptions,Card } from 'antd';



const DepartmentDetail = ({selectedDepartment}) => {


    

    const items = [
        {
          key: '1',
          label: 'รหัสสาขา : ',
          children: selectedDepartment.id,
        },
        {
          key: '2',
          label: 'ชื่อสาขา : ',
          children: selectedDepartment.department,
        },
        {
            key: '3',
            label: 'เบอร์ติดต่อ : ',
            children: selectedDepartment.telephone,
          },
        {
          key: '4',
          label: 'ที่อยู่ : ',
          children: selectedDepartment.address,
        },
        {
          key: '5',
          label: 'ใบอนุญาตสถานพยาบาล : ',
          children: selectedDepartment.hospital_license,
        },
        {
          key: '6',
          label: 'เลขประจําตัวผู้เสียภาษี : ',
          children: selectedDepartment.taxpayer_identification_number,
        },
    

      ];


  return (
    <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[1rem] my-10 rounded-3xl' >
    <div className='p-[24px] bg-white shadow-2xl rounded-3xl'>
        <Descriptions
        title="ข้อมูลสาขา"
        bordered
        column={{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        items={items}
    />
    </div>
    </div>
  )
}

export default DepartmentDetail