import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';



import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Cookies from 'js-cookie';
import { Descriptions, Button, Modal,Input,Form,Space,Radio} from 'antd';
import './PatientProfile.css'
import {css} from '@emotion/css'



const {TextArea} = Input;

const SubmitButton = ({form,handModalCancel,setpatient}) => {
    const [submittable, setSubmittable] = React.useState(false);
  
    const values = Form.useWatch([], form);

    const newValues = {...values, known_form_other: values?.known_form === 'อื่นๆ' ? values?.known_form_other : ''}
  
    const handleClick = async() =>{

        const response = await axios.put(process.env.REACT_APP_API_URL+`/patient/byId/${values.id}` ,newValues ,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setpatient(response.data)
        handModalCancel()
  
      
    
    }
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          },
        );
    }, [values]);
  
    return (
      <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleClick} className='bg-[#1677FF]'>
        ตกลง
      </Button>
    );
  };



const PatientProfile = () => {
    let {id} = useParams();
    const [patient, setPatient] = useState({});
    const [clinic, setClinic] = useState({});
    const [pclinic, setPclinic] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isLoading2, setLoading2] = useState(false);
    const [filterClinic, setFilterClinics] = useState({})
    const [selectedClinicId, setSelectedClinicId] = useState(1);
    const [selectedAds , setSelectedAds] = useState() 

    const [isModalOpen, setIsmodalOpen] = useState(false)

    const [form] = Form.useForm();



    const handleModalCancle = () =>
    {
        setIsmodalOpen(false);
        form.resetFields();
    }

    const handleModalOpen = () =>
    {
        setIsmodalOpen(true);
        form.setFieldsValue(patient);
        setSelectedAds(patient.known_form)
    }



    const descriptionCSS = css({
      ':where(.css-dev-only-do-not-override-nnuwmp).ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view .ant-descriptions-row > .ant-descriptions-item-label': {
        backgroundColor: 'rgba(0, 0, 0, 0.02);',
        
      },
    });


    const items = [
        {
          key: '1',
          label: <b>รหัสคนไข้ :</b>,
          children: patient.id,
        },
        {
          key: '2',
          label: <b>ชื่อสาขา : </b>,
          children: patient.name,
        },
        {
            key: '3',
            label: <b>อายุ : </b>,
            children: patient.age,
        },
        {
            key: '4',
            label: <b>ID : </b>,
            span: 2,
            children: patient.idd,
          },
        {
          key: '5',
          label: <b>HN : </b>,
          span: 2,
          children: patient.HN,
        },
        {
            key: '6',
            label: <b> เบอร์ติดต่อ: </b>,
            span: 2,
            children: patient.call_data ,
          },

          
          {
            key: '7',
            label: <b>email : </b>,
            span: 2,
            children: patient.email,
          },
          {
            key: '8',
            label: <b>ที่อยู่ : </b>,
            children: patient.Address,
            span: 3,
          },
          {
            key: '9',
            label: <b>ประวัติการรักษา : </b>,
            span: 2,
            children: patient.treatment_history,
          },
          {
            key: '10',
            label: <b>โรคประจำตัว : </b>,
            span: 2,
            children: patient.chronic_Disease,
          },
          {
            key: '11',
            label: <b>รู้จักจาก : </b>,
            span: 3,
            children: patient?.known_form === 'อื่นๆ' ? patient?.known_form_other : patient?.known_form,
          },
        

      ];





    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + `/patient/byId/${id}`,{ headers: { accessToken: Cookies.get('accessToken') } }).then((response) => {
          setPatient(response.data);
        axios.get(process.env.REACT_APP_API_URL+`/patient/clinic/${id}`,{ headers: { accessToken: Cookies.get('accessToken') } }).then((cresponse) =>{
            setPclinic(cresponse.data)
        axios.get(process.env.REACT_APP_API_URL + `/patient/clinic`,{ headers: { accessToken: Cookies.get('accessToken') } }).then((clinicResponse) => {
        setClinic(clinicResponse.data);     
        setFilterClinics(clinicResponse.data.filter((obj) => cresponse.data.map((item) => item.department_id).includes(obj.id)));
        setLoading(false);
        })
        })
        });
      }, []);

      if (isLoading){
        return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
        <CircularProgress color="inherit" />
      </Backdrop>
       ;
    }


  return (
    <>  
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading2}>
            <CircularProgress color="inherit" />
        </Backdrop>


        <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl shadow-md md:mx-[10rem] mx-[1rem] my-10 rounded-3xl' >
          <div className='p-[24px] bg-white md:shadow-2xl rounded-3xl'>
            <Descriptions
            bordered
            title="ข้อมูลคนไข้"
            //column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
            extra={<Button className='bg-[#3677FF] text-white' onClick={handleModalOpen}>แก้ไข้</Button>}
            items={items}
            size={'small'}
            className={descriptionCSS}
                />
          </div>
        </div> 

        <Modal open={isModalOpen} onCancel={handleModalCancle} footer={[]} width={1000}>
            <Form
            form={form}
            name="validateOnly"
            layout="horizontal"
            autoComplete="off"
            labelCol={{ span: 4 }}
            className='pt-10'
            >
            <Form.Item
            name="id"
            label="รหัสคนไข้"
            >
            <Input readOnly={true} disabled/>
            </Form.Item>
            <Form.Item
            name="name"
            label="ชื่อคนไข้"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกชื่อคนไข้',
            },
            ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
            name="age"
            label="อายุ"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกอายุ',
            },
            {
            pattern: /^[0-9]+$/,
            message: 'อายุต้องอยู่ในช่วง 0-100 ปี',
            },
            ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
            name="HN"
            label="เลขHN"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกเลข HN',
            },
            {
            pattern: /^[0-9]+$/,
            message: 'กรุณากรอกเลข HN เป็นตัวเลขเท่านั้น',
            },
            ]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            name="idd"
            label="หมายเลขบัตรประชาชน"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกหมายเลขบัตรประชาชน',
            },
            {
            pattern: /^[0-9]+$/,
            message: 'กรุณากรอกหมายเลขบัตรประชาชนเป็นตัวเลขเท่านั้น',
            },
            ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
            name="Address"
            label="ที่อยู่"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกที่อยู่',
            },
            ]}
            >
            <TextArea maxLength={500}/>
            </Form.Item>
            <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'กรุณากรอกอีเมล',
              },
              {
                validator: async (_, value) => {
                  if (value === "-" || (value && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('รูปแบบอีเมลไม่ถูกต้อง'));
                },
              }
            ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
            name="call_data"
            label="เบอร์โทรศัพท์"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกเบอร์โทรศัพท์',
            },
            {
            pattern: /^[0-9]+$/,
            message: 'กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลขเท่านั้น',
            },
            ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
            name="treatment_history"
            label="ประวัติการรักษา"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกประวัติการรักษา',
            },
            ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
            name="chronic_Disease"
            label="โรคประจำตัว"
            rules={[
            {
            required: true,
            message: 'กรุณากรอกโรคประจำตัว',
            },
            ]}
            >
            <Input />
            </Form.Item>

            <Form.Item 
          label="รู้จักจาก"
          name="known_form"
          rules={[
            {
              required: true,
              message: 'กรุณาเลือกประเภท',
            },
          ]}
        >
          <Radio.Group onChange={(e) => setSelectedAds(e.target.value)}>
            <Radio value="Facebook"> Facebook </Radio>
            <Radio value="Google"> Google </Radio>
            <Radio value="Youtube"> Youtube </Radio>
            <Radio value="Tiktok"> Tiktok </Radio>
            <Radio value="ป้ายโฆษณา"> ป้ายโฆษณา </Radio>
            <Radio value="บอกต่อ"> บอกต่อ </Radio>
            <div className='flex items-center'>
              <Radio value="อื่นๆ"> อื่นๆ </Radio>
            {selectedAds === 'อื่นๆ' && (
                <Form.Item style={{ marginBottom: "0px" }}
                  name="known_form_other"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกข้อมูล',
                    },
                  ]}
                >
                  <Input />
              </Form.Item>
              )}
            </div>
          </Radio.Group>
        </Form.Item>


            <Form.Item>
            <Space>
            <SubmitButton form={form} handModalCancel={handleModalCancle} setpatient={setPatient} />
            <Button htmlType="reset" onClick={handleModalCancle}>ยกเลิก</Button>
            </Space>
            </Form.Item>
            </Form>

        </Modal>
    </>
  )
}

export default PatientProfile