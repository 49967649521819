import React, { useEffect, useState , useContext } from 'react';
import { Table ,  Popover} from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import {css} from '@emotion/css'
import { useParams } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContex';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";
const transformData = (inputData) => {
    const rowData = [];
  
    inputData.forEach((item) => {
      const existingDate = rowData.find((data) => data.date === item.date);
  
      if (existingDate) {
        existingDate.employee.push({
          name: item.employee_name,
          handfee_times: item.handfee_times,
        });
      } else {
        rowData.push({
          date: item.date,
          employee: [
            {
              name: item.employee_name,
              handfee_times: item.handfee_times,
            },
          ],
        });
      }
    });
  
    return rowData;
  };

  const generateDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let currentDate = startDate;
    const dates = [];
  
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dates.map(date => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`; // format date as 'dd/mm/yyyy'
    });
  };

const ComissionHandFee = () => {
  const { id } = useParams();
  
  const {authState} = useContext(AuthContext)

  const [employee, setEmployee] = useState();
  const [comission, setComssion] = useState();
  const [optimizeData, setOptimizeData] = useState();
  const [isLoading, setLoading] = useState(true);
 

  useEffect(() => {
    const fetchdata = async () => {
      const employee = await axios.get(process.env.REACT_APP_API_URL + `/employee/byId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},});
      setEmployee(employee.data);
      const comission = await axios.get(process.env.REACT_APP_API_URL+ `/comission/get-comission-month-handfee/byDp&Date/${authState.department_id}/${id}`, { headers:{accessToken: Cookies.get('accessToken'),},});
      console.log(comission.data)
      setComssion(comission.data);
  
      const comissionWithName = comission.data.map((item) => {
        const employees = employee.data.find((e) => e.id === parseInt(item.employee_name));
        return { ...item, employee_name:employees?.name };
      });


      

      

      const [month,year] = id.split('-');
      const startDate = `${year}-${month}-01`;
      const endDate = new Date(year, month, 0).getDate();
      const allDates = generateDateRange(startDate, `${year}-${month}-${endDate}`);
      const newAllDates = [...allDates,'29/02/2567']
      const mergedData = newAllDates.map(date => {
        const existingData = transformData(comissionWithName).find(item => item.date === date);
        return existingData ? existingData : { date, employee: [] };
      });

      setOptimizeData(mergedData);
      setLoading(false)
    };
    fetchdata();
  }, []);

  const employeeDataMap = {};
  employee?.forEach((item) => {
    employeeDataMap[item.name] = item;
  });



  const tableData = Array.isArray(optimizeData) ? optimizeData.map((row) => {
    const tableRow = {
      key: row.date,
      date: row.date,
    };
    employee?.forEach((item) => {
      tableRow[item.name] = "0";
    });

    row.employee.forEach((employeeEntry) => {
      const employeeName = employeeEntry.name;
      const employeeData = employeeDataMap[employeeName];
      if (employeeData) {
        tableRow[employeeName] = employeeEntry.handfee_times || "0";

      }
    });

    return tableRow;
    }) : [];


    const summaryRow = {
        key: 'summary',
        date: 'รวม(ครั้ง)',
      };

    const calculatedSummaryRow = 
    {
      key:'sumaryCal',
      date:'รวม(คำนวณ)'
    }
    
      employee?.forEach((item) => {
        let sum = 0;
        tableData.forEach((row) => {
          sum += parseInt(row[item.name]) || 0;
        });
        summaryRow[item.name] = sum.toString();
        calculatedSummaryRow[item.name] = (sum*item.handfee).toString();
      });

    



  const columns = [
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      align:'center',
      width:'6%',
      fixed:'left'
    },
    {
      title: 'รายชื่อพนักงาน',
      className: 'custom-header',
      
      children: employee?.map((item) => ({
        title :
        ( 
          <Popover placement="top" className='cursor-pointer' content={
            (
              <>
            <div>
              ชื่อ : {item.name}
            </div>
            <div>
            ชื่อเล่น : {item.nickname}
          </div>
          <div>
              ตำแหน่ง : {item.role}
            </div>
            <div>
            ค่าหัต : {item.handfee}
          </div>
      </>
            )
          }
            >
          ({item.nickname})
          </Popover>
        ),
        dataIndex: item.name,
        key: item.name,
        align: "center",
      })),
    },
  ];

  const tableCSS = css({
    backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse'
    },
    '& thead > tr': {
      borderWidth: '1px',
      borderColor: 'black',
      borderStyle: 'solid',
      backgroundColor: "blue"
    },
    '.ant-table-thead .ant-table-cell':
    {
      backgroundColor: '#00BA9E',
      color: 'white',
      borderRadius: 'unset !important'
    },


    
  });
  if (isLoading)
    {
      return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
      <CircularProgress color="inherit" />
      </Backdrop>
     ;
    }

  return (
    <>
      <div className='flex items-end justify-center mt-2'>
            <h5 className='md:text-[3rem] text-[2rem] mb-[10px] '>สรุปค่ามือ</h5>
      </div>
      <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl mb-10' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl pb-[24px]`}>
        <Table columns={columns} dataSource={[...tableData,summaryRow,calculatedSummaryRow]} rowKey="key" size="small"  className={tableCSS} pagination={false} scroll={{x:'max-content',y: window.innerWidth < 786 ? null : 600}}/>
        </div>
      </div>
    </>
  );
};

export default ComissionHandFee;
