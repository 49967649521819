import React,{useEffect,useState,useContext} from 'react'

import EmployeeListcom from '../component/EmployeeList'
import EmployeeDetail from '../component/EmployeeDetail';
import { AuthContext } from '../helpers/AuthContex';

import axios from 'axios';
import Cookies from 'js-cookie';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EmployeeLogo from '../asset/Logo/employee.png'
axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";
const Employee = () => {
  const {authState ,setAuthState} = useContext(AuthContext)
  const [isLoading, setLoading] = useState(true);
  const [employee, setListOfEmployee] = useState([]);
  const [selectedEmployee, setSelectedemployee] = useState({})
  const [clinic, setListOfClinic] = useState([])

  const handleRowDelete = async (key) =>{
    const response = await axios.delete(process.env.REACT_APP_API_URL+`/employee/${key}`, { headers:{accessToken: Cookies.get('accessToken'),},}) 
  }

  useEffect(() => {

    const fetchdata = async () =>{
        const employee = await axios.get(process.env.REACT_APP_API_URL + `/employee`, { headers:{accessToken: Cookies.get('accessToken'),},})
        if(authState.department_id !== 0 )
        {
          const filtersEmployee = employee.data.filter(item => item.department_id === authState.department_id )
          setListOfEmployee(filtersEmployee)
        }
        else
        {
          setListOfEmployee(employee.data)
        }
        const clinics = await axios.get(process.env.REACT_APP_API_URL + `/patient/clinic`,{ headers:{accessToken: Cookies.get('accessToken'),},})
        setListOfClinic(clinics.data)
    }

    fetchdata()
    setLoading(false)
  }, []);

  

  if (isLoading){
    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
    <CircularProgress color="inherit" />
  </Backdrop>
   ;
}


  return (
    <>
    <div className='flex items-end justify-center mt-1'>
        <img src={EmployeeLogo} alt='departmentLogo' className='md:w-[5rem] w-[3rem]'/>
      <h5 className='md:text-[3rem] text-[2rem] !mb-[0] '>รายชื่อพนักงาน</h5>
    </div>
    <div className='mb-1'>
    <EmployeeDetail selectedEmployee={selectedEmployee} />
    </div>

    <div>
    <EmployeeListcom employee={employee} setListOfEmployee={setListOfEmployee} setLoading={setLoading} setSelectedemployee={setSelectedemployee} clinic={clinic} />
    </div>
    </>
  )
}

export default Employee