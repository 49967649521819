import React,{useContext} from 'react'
import { AuthContext } from '../helpers/AuthContex';
import {Button} from 'antd'
import {useNavigate } from 'react-router-dom';
import {ScheduleFilled , UserOutlined, ProfileOutlined  ,SettingOutlined, ReconciliationOutlined} from '@ant-design/icons';
import TrustmeLog from '../asset/logo_auto.png'
import TrustmeLog2 from '../asset/logo_auto2.png'
function Dashboard(){
  const{departmentlabel,departmentLogo,authState} = useContext(AuthContext)
  let nv = useNavigate();
  return (
    <div className='flex md:flex-row flex-col  md:justify-between md:mx-[10rem] md:mt-20 mt-10' >
      <div className='md:w-[40rem] md:h-[40rem] items-center flex-col flex '>
          <img src={departmentLogo} alt='logo' className='md:w-[25rem] md:h-[25rem] w-[8rem] h-[8rem] animate-loop-show drop-shadow-2xl object-contain'/>
          <div className='md:block flex items-center gap-2'>
          <h1 className='md:text-[3rem] text-[2rem] font-bold'> Trustme Clinic</h1>
          <h1 className='md:text-[2rem]  text-[1rem]'> สาขา: {departmentlabel}</h1>
          </div>
      </div>
      <div>
        <div className='flex flex-wrap justify-center'>
        <Button className=' bg-[#79aa41] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px] '  style={{ zIndex: 0 }} onClick={()=>{nv('/patientlist')}}> <> <UserOutlined className=' md:text-[100px] text-[3.5rem] text-[#000000]'/><h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white'>จัดการคนไข้</h1></></Button>
        <Button className=' bg-[#7ce25d] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]'  onClick={()=>{nv('/scheduler')}}><> <ScheduleFilled className=' md:text-[100px] text-[#000000] text-[3.5rem]'/><h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white'>ตารางนัด</h1></></Button>
        <Button className=' bg-[#e0de44] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]'  onClick={()=>{nv('/bill')}}> <> <ProfileOutlined className=' md:text-[100px] text-[#000000] text-[3.5rem]'/> <h1 className='md:text-3xl  text-[1rem] font-bold md:pt-5 text-white'>ใบเสร็จ</h1></></Button>
        <Button className=' bg-[#dda845] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem]  m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]'  onClick={()=>{nv('/comission')}}><> <ReconciliationOutlined className=' md:text-[100px] text-[3.5rem] text-[#000000]'/><h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white'>ค่ามือเเละค่าแนะนำ</h1></></Button>
        {authState.role === 'admin' &&<Button className=' bg-[#d36c43] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem]  m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]'  onClick={()=>{nv('/admin')}}> <><SettingOutlined className=' md:text-[100px] text-[3.5rem] text-[#000000]'/><h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white'>การตั้งค่า[admin]</h1></></Button>}
        </div>
      </div>
    </div>
  )
}

export default Dashboard