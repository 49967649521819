import { Outlet, Navigate } from "react-router-dom"


const AdminRoutes = ({token}) => {
    let auth = {'token': token}
  return (
    auth.token === "admin" ? <Outlet/> : <Navigate to = {'/login'}/>
  )
}

export default AdminRoutes