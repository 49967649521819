import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import Angsana from '../asset/fonts/ANGSA.ttf';

import {
    Table,
    TableHeader,
    TableCell,
    TableBody,
    DataTableCell,
  } from "@david.kucsai/react-pdf-table";
  
Font.register({ family: 'Angsana', src: Angsana });


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'Angsana',
        padding: 10,
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
    },
});

const BillSummaryMonthDocument = ({ data, d_label }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.contentContainer}>
                    <Text style={styles.title}>สรุปยอดรายการใบเสร็จ สาขา: {d_label}</Text>
                </View>
                <View>
                    <Table data={data}>
                            <TableHeader>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    เดือนที่
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey' ,fontSize:'11' }}>
                                    จำนวนรายการ  _  
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey' ,fontSize:'11' }}>
                                    เงินสด(ครั้ง)
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey' ,fontSize:'11' }}>
                                    โอน(ครั้ง)
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    บัตรเครดิต/เดบิต(ครั้ง)
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    <Text>
                                    ไม่มี
                                    </Text>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey',fontSize:'11' }}>
                                    <Text>
                                    ยอดขาย(บาท)
                                    </Text>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center',backgroundColor:'grey', fontSize:'11'}}>
                                    หมายเหตุ
                                </TableCell>
                            </TableHeader>
                            <TableBody>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.monthYear}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.amount}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.cash}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.mobile_banking}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.card}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.noPay}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => r.total_amount}/>
                                <DataTableCell style={{ textAlign: 'center' }} getContent={(r) => " "}/>
                            </TableBody>
                        </Table>
            </View>
    </Page>
  </Document>
  );
          };

export default BillSummaryMonthDocument;
