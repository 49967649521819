import React, { useContext , useState} from 'react'
import './createCustomer.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AuthContext } from '../helpers/AuthContex';

import { Button, Checkbox, Form, Input,Card } from 'antd';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TrustmeLog from '../asset/logo_auto.png'
import TrustmeLog2 from '../asset/logo_auto2.png'
axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";



const Login = () => {
  
  const{setAuthState,setdepartmentlabel,setDepartmentLogo ,clinic, authState , appSetup} = useContext(AuthContext)
  const [isLoading, setLoading] = useState(false);

  const history = useNavigate();


  const onSubmit = async (data) => {
    setLoading(true);
  
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/login', data);
  
      if (response.data.error) {
        alert(response.data.error);
      } else {
        if (data.remember) {
          Cookies.set('accessToken', response.data.token, { expires: 365 });
        } else {
          Cookies.set('accessToken', response.data.token);
        }
  
        setAuthState({
          username: response.data.username,
          id: response.data.id,
          status: true,
          role: response.data.role,
          department_id: response.data.department_id
        });
  
        const dresponse = await appSetup(response.data.token);
  
        const currentDepartment = dresponse.data.find(item => item.id === response.data.department_id);
        setdepartmentlabel(currentDepartment?.department || 'ทั้งหมด');
        if(currentDepartment){
          setDepartmentLogo(currentDepartment?.clinic_type === 1 ? TrustmeLog : TrustmeLog2);
        }else{
          setDepartmentLogo(TrustmeLog);
        }
        
        history('/');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred during login. Please try again later.');
    } finally {
      if (authState.department === 0) {
      }
      setLoading(false);
    }
  };
  

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    
              <div className=' flex flex-1  w-full  justify-center items-center'>
                <div className='md:p-[1rem] mt-[10rem] md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl ' >
                <Card className='rounded-2xl shadow-lg'>
                <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 1000,
              }}
              initialValues={{
                remember: false,
              }}
              onFinish={onSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size='large'
            >
              <Form.Item
                label="ชื่อผู้ใช้"
                name="username"
                
                rules={[
                  {
                    required: true,
                    message: 'กรุณาการชื่อผู้ใช้',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="รหัสผ่าน"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'กรุณากรอกรหัสผ่าน',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
              >
                <Checkbox >จดจำฉันไว้</Checkbox>
              </Form.Item>
                <div className='flex flex-1  justify-center'>
              <Form.Item
              >
                <Button type="primary" htmlType="submit" className='bg-[#7877FF]'>
                  ลงชื่อเข้าใช้
                </Button>
              </Form.Item>
              </div>
            </Form>
      </Card>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
      <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
      <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Login