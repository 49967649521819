import React from 'react'



import TabsCourse from '../component/Tabs_course';
import PatientProfile from '../component/PatientProfile';


function Patient() {
  return (
    <>
    <div className='pb-5'>
      <PatientProfile/>

      <TabsCourse />
      </div>
    </>
    
  )
}

export default Patient