import React, { useState ,useRef} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {Modal , Button, Input, Table, Form ,Space,Select,Radio} from 'antd'

import { SearchOutlined } from '@ant-design/icons';
import './PatientList.css'
import {css} from '@emotion/css'

const {TextArea} = Input;

const SubmitButton = ({ form, handleModalCancel, setListOfEmployee , setLoading ,fetchData}) => {
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);

  const handleClick = async() =>{
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_API_URL + `/patient/clinic`,values,{ headers:{accessToken: Cookies.get('accessToken'),}})
    fetchData();
    handleModalCancel();
    setLoading(false)
  }

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleClick} className='bg-[#1677FF]'>
      ตกลง
    </Button>
  );
};

const SubmitButton2 = ({form, setListOfEmployee,handleEditModalCancel,setLoading ,fetchData}) => {
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);

  const handleClick = async() =>{
    setLoading(true)
    const response = await axios.put(process.env.REACT_APP_API_URL + `/patient/clinic/${values.id}`,values,{ headers:{accessToken: Cookies.get('accessToken'),}})
    fetchData();
    handleEditModalCancel();
    setLoading(false)
  }

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleClick} className='bg-[#1677FF]'>
      ตกลง
    </Button>
  );
};



const DepartmentListcom = ({setLoading,setListOfdepartment,department, setSelectedDepartment, fetchData}) => {

  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()

  const [isModalopen, setIsmodalOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)

  const [isModalComissionOpen , setIsModalComissionOpen] = useState(false)
  const [comissionData, setComissionData] = useState()

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [isForm3Valid, setIsForm3Valid] = useState(false);

  const clinicTypeOptions = [{value:1,label:"Trustme plus"}, {value:2,label:"Trustme"}]

  const handleModalCancel = () =>
  {
    form.resetFields();
    setIsmodalOpen(false)
  }

  const handleEditModalCancel=()=>
  {
    setIsModalEditOpen(false)
  }

  // const handleRowDelete = async (key) => 
  // {
  //   const response = await axios.delete(process.env.REACT_APP_API_URL+`/patient/clinic/${key}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
  //   const clinic = await axios.get(process.env.REACT_APP_API_URL + `/patient/clinic`,{ headers:{accessToken: Cookies.get('accessToken'),}})
  //   setListOfdepartment(clinic.data)
  // }

  const handleRowEdit = (record) => {
    setIsModalEditOpen(true);
    form2.setFieldsValue(record);
  };

  const handleComission = (record) =>
  {
    setIsModalComissionOpen(true);
    const currentDate = new Date().toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const data = {
      date:currentDate,
      department_id:record.id,
      name: record.department
    }
    setComissionData(data)
  }

  const handleComissionCancle = ()=>
  {
    setIsModalComissionOpen(false);
    setComissionData({})
    form3.resetFields();
    setIsForm3Valid(false)
  }
   
  const handleComissionConfirm = async() =>
  {
    setLoading(true)
    const response = form3.getFieldValue('options') === '3percent'?  await axios.post(process.env.REACT_APP_API_URL+ `/comission/enable-threepercent`, comissionData,{ headers:{accessToken: Cookies.get('accessToken'),}}) : await axios.post(process.env.REACT_APP_API_URL+ `/comission/enable-fourpercent`, comissionData,{ headers:{accessToken: Cookies.get('accessToken'),}})
    fetchData();
    handleComissionCancle();
    setLoading(false)
    form3.resetFields();
    setIsForm3Valid(false)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`ค้าหา ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className='bg-[#7877FF]'
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'รหัสสาขา',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
    },
    {
        title: 'ชื่อสาขา',
        dataIndex: 'department',
        key: 'department',
        ...getColumnSearchProps('department'),
      },
      {
        title: 'ที่อยู่',
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: 'เบอร์โทรติดต่อ',
        dataIndex: 'telephone',
        key: 'telephone',
        
      },
      {
        title: 'ใบอนุญาตสถานพยาบาล',
        dataIndex: 'hospital_license',
        key: 'hospital_license',
        width:"15%",
        
      },
      {
        title: 'เลขประจําตัวผู้เสียภาษี',
        dataIndex: 'taxpayer_identification_number',
        key: 'taxpayer_identification_number',
        width:"15%",
      },
      {
        title: 'ประเภท',
        dataIndex: 'clinic_type',
        key: 'clinic_type',
        align:'center'
      },
      {
        title: 'จัดการ',
        dataIndex: 'operation',
        align:'center',
        render: (_, record) =>
        {return (
          <Space>
              <a href="#/" onClick={()=>{handleRowEdit(record)}} className=' text-blue-400 font-bold' >แก้ไข</a>
              {record.permission === false ? <a href="#/" onClick={()=>{handleComission(record)}} className=' text-blue-400 font-bold whitespace-nowrap' >เพิ่มค่าแนะนำ</a> : null}
          </Space>
        )}
        ,
        width:"10%"
      },
      
  ];
  




  const handleRowClick = (t) => {
    setSelectedDepartment(t)
  }


const tableCSS = css({
  backgroundColor: 'white',
  '& table': {
    borderCollapse: 'collapse'
  },
  '& thead > tr': {
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: "blue"
  },
  '.ant-table-thead .ant-table-cell':
  {
    backgroundColor: '#00BA9E',
    color: 'white',
    borderRadius: 'unset !important'
  }
  
});

    return (
        <>
        <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl ' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl ${(department?.length === 0 ) ? 'pb-[24px]' : null}`}>
        <Table
            columns={columns}
            dataSource={department}
            rowKey="id" 
            className={tableCSS}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {handleRowClick(record)}, // click row
              };
            }}
            size='small'
            scroll={{x:'max-content'}}
        />
        </div>
        </div>

        <Button className='bg-white m-5' onClick={()=>{setIsmodalOpen(true)}}>เพิ่มสาขา</Button>

          <Modal width={1000} title="เพิ่มสาขา" open={isModalopen}  onCancel={handleModalCancel} footer={false}> 
            <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item
                name="department"
                label="ชื่อสาขา"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder={"ชื่อสาขา"}/>
              </Form.Item>
              <Form.Item
                name="address"
                label="ที่อยู่"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea placeholder={"ที่อยู่"}/>
                </Form.Item>
                <Form.Item
                name="telephone"
                label="เบอร์ติดต่อ"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: 'กรุณากรอกเบอร์โทรศัพท์ 10 หลัก'
                  }
                ]}
              >
                <Input placeholder={"เบอร์ติดต่อ"}/>
              </Form.Item>
              <Form.Item
                name="hospital_license"
                label="ใบอนุญาตสถานพยาบาล"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input  placeholder={"ใบอนุญาตสถานพยาบาล"}/>
              </Form.Item>
              <Form.Item
                name="taxpayer_identification_number"
                label="เลขประจําตัวผู้เสียภาษี"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input  placeholder={"เลขประจําตัวผู้เสียภาษี"} />
              </Form.Item>
              <Form.Item
                name="clinic_type"
                label="ประเภทคลินิก"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={clinicTypeOptions} placeholder={"ประเภทคลินิก"}/>
              </Form.Item>
              


              <Form.Item>
                <Space>
                  <SubmitButton form={form} handleModalCancel={handleModalCancel} setListOfEmployee={setListOfdepartment} setLoading={setLoading} fetchData={fetchData}/>
                  <Button htmlType="reset" onClick={handleModalCancel}>ยกเลิก</Button>
                </Space>
              </Form.Item>
            </Form>
        </Modal>


        <Modal width={1000} title="แก้ไขสาขา" open={isModalEditOpen}  onCancel={handleEditModalCancel} footer={false}> 
            <Form form={form2} name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item
                name="id"  
                label="รหัสสาขา" 
                >
              <Input readOnly /> 
              </Form.Item>
              <Form.Item
                name="department"
                label="ชื่อสาขา"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder={"ชื่อสาขา"}/>
              </Form.Item>
              <Form.Item
                name="address"
                label="ที่อยู่"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea placeholder={"ที่อยู่"}/>
                </Form.Item>
                <Form.Item
                name="telephone"
                label="เบอร์ติดต่อ"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: 'กรุณากรอกเบอร์โทรศัพท์ 10 หลัก'
                  }
                ]}
              >
                <Input placeholder={"เบอร์ติดต่อ"}/>
              </Form.Item>
              <Form.Item
                name="hospital_license"
                label="ใบอนุญาตสถานพยาบาล"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input  placeholder={"ใบอนุญาตสถานพยาบาล"}/>
              </Form.Item>
              <Form.Item
                name="taxpayer_identification_number"
                label="เลขประจําตัวผู้เสียภาษี"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input  placeholder={"เลขประจําตัวผู้เสียภาษี"} />
              </Form.Item>
              <Form.Item
                name="clinic_type"
                label="ประเภทคลินิก"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={clinicTypeOptions} placeholder={"ประเภทคลินิก"}/>
              </Form.Item>


              
              <Form.Item>
                <Space>
                  <SubmitButton2 form={form2} handleModalCancel={handleEditModalCancel} setListOfEmployee={setListOfdepartment} handleEditModalCancel={handleEditModalCancel} setLoading={setLoading} fetchData={fetchData}/>
                  <Button htmlType="reset" onClick={handleEditModalCancel}>ยกเลิก</Button>
                </Space>
              </Form.Item>
            </Form>
        </Modal>

        <Modal  title="เพิ่มค่าแนะนำเป็น3%" open={isModalComissionOpen} onCancel={handleComissionCancle}   footer={[<Button disabled={!isForm3Valid}  onClick={handleComissionConfirm} >ตกลง</Button> ,<Button onClick={handleComissionCancle}>ยกเลิก</Button>]}> 
            {comissionData &&
            (
              <>
                <div>วันที่ : {comissionData.date}</div>
                <div>รหัสสาขา : {comissionData.department_id}</div>
                <div>ชื่อสาขา : {comissionData.name}</div>
              </>
              
            )}
            <Form form={form3} name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item
                name="options"
                label="ตัวเลือก"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group onChange={()=>{setIsForm3Valid(true)}} >
                    <Radio value="3percent"> เพิ่มค่าแนะนำ[สำหรับคอร์ส]เป็น3 % </Radio>
                    <Radio value="4percent"> เพิ่มค่าแนะนำ[สำหรับคอร์ส]เป็น4 % </Radio>
                </Radio.Group>
              </Form.Item>
            </Form>

        </Modal>


        </>
    );
    }

export default DepartmentListcom