import React from 'react'

const Othersetting = () => {
  return (
    <>
    <div>
        จัดการส่วนลด
    </div>
    <div>
        จัดการComission
    </div>
    </>
  )
}

export default Othersetting