import React from 'react'
import Billlist from '../component/Billlist';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";


const Bills = () => {


  return (
    <>
    <div className='pb-5'>
      <div className='flex  items-end justify-center mt-2'>
        <h5 className='md:text-[3rem] text-[2rem] mb-[10px] '>รายการใบเสร็จ</h5>
      </div>
      <Billlist/>
    </div>
    
    </>
  )
}

export default Bills