import React,{useEffect,useState} from 'react'


import DepartmentListcom from '../component/Departmentlist';
import DepartmentDetail from '../component/DepartmentDetail';

import axios from 'axios';
import Cookies from 'js-cookie';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import departmentLogo from "../asset/Logo/department.png"


axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";
const Department = () => {
  
  const [isLoading, setLoading] = useState(true);
  const [departments, setListOfdepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({})

  const fetchdata = async () =>{
    setLoading(true)
    const currentDate = new Date().toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const data =
    {
      date: currentDate
    }
    const department = await axios.get(process.env.REACT_APP_API_URL + `/patient/clinic`, { headers:{accessToken: Cookies.get('accessToken'),},})
    const departmentWithPermission = await axios.post(process.env.REACT_APP_API_URL + `/comission/get-today-permission` ,data, { headers:{accessToken: Cookies.get('accessToken'),},})
    const formatData = department.data.map(item => (
      {
        ...item, permission: departmentWithPermission.data.some(permissionItem => permissionItem.department_id === item.id)
      }))
    console.log(formatData)
    setListOfdepartment(formatData)
    setLoading(false)
}

  useEffect(() => {
    fetchdata()
  }, []);

  if (isLoading){
    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
    <CircularProgress color="inherit" />
  </Backdrop>
   ;
}


  return (
    <>
    <div className='flex items-end justify-center mt-2'>
      <img src={departmentLogo} alt='departmentLogo' className='md:w-[5rem] w-[3em]'/>
      <h5 className='md:text-[3rem] text-[2rem] !mb-[0] '>รายชื่อสาขา</h5>
    </div>
    <div>
    <DepartmentDetail selectedDepartment={selectedDepartment}/>
    </div>
    <div>
    <DepartmentListcom setLoading={setLoading} department={departments} setListOfdepartment={setListOfdepartment} setSelectedDepartment={setSelectedDepartment} fetchData={fetchdata}/>
    </div>
    </>
  )
}

export default Department