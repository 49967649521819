import React,{ useContext ,useState} from 'react'
import PatientListcom from '../component/PatientList'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../helpers/AuthContex';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Button,Modal,Form ,Input,Space ,Radio,Select,Table,message} from 'antd';
import patietnLogo from '../asset/Logo/patient.webp'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {css} from '@emotion/css'

const {TextArea} = Input


axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";

const SubmitButton = ({form, authState , handModalCancel, setLoading , setIsTempOpen, setFindPatientResponseData}) => {
  let nav = useNavigate();
  const [submittable, setSubmittable] = React.useState(false);

  const values = Form.useWatch([], form);
  const newValue = {...values,department_id:authState.department_id}
   

  const handleClick = async() =>{
    setLoading(true)

    const response = await axios.post(process.env.REACT_APP_API_URL+'/patient', newValue, { headers:{accessToken: Cookies.get('accessToken'),},})
      if(response.data.error)
      {
        setIsTempOpen(true)
        setFindPatientResponseData(response.data.error)
        setLoading(false);
        return;
      }
    handModalCancel();
    setLoading(false);
    nav(`/patient/${response.data.id}/${authState.department_id}`)

    
  
  }
  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleClick} className='bg-[#1677FF]'>
      ตกลง
    </Button>
  );
};

function PatientList() {
  const{authState} = useContext(AuthContext)
  const finderOptions = [{value:"name",label:"ชื่อ"},{value:"number",label:"เบอร์โทรศัพท์"},{value:"idd",label:"เลขบัตรประชาชน"}]
  const [Ismodalopen,setIsmodalopen] = useState()
  const [isLoading, setLoading] = useState(false);
  const [selectedAds, setSelectedAds] = useState();

  const [isFindPatientModalOpen, setIsFindPatientModalOpen] = useState(false)

  const [isPatientResponseOpen, setIsPatientResponseOpen] = useState(false)
  const [findPatientResponseData, setFindPatientResponseData] = useState([]);

  const [form] = Form.useForm();
  const [findPatientForm] = Form.useForm();

  const [isTempOpen,setIsTempOpen] = useState(false)
  
  const navi = useNavigate()

  const [messageApi, contextHolder] = message.useMessage();
  const dispalyPatientDepartmentError = (text) => {
    messageApi.open({
      type: 'error',
      content: text,
    });
  };

  const handModalCancel =()=>{
    setIsmodalopen(false)
    form.resetFields();
  }

  const handFindPaitientModalCancel = ()=>{
    setIsFindPatientModalOpen(false)
    findPatientForm.resetFields();
    
  }
  const handFindPaitientModalFinish = async()=>{
    const data = findPatientForm.getFieldsValue()
    console.log(data)
    const response =  await axios.post(process.env.REACT_APP_API_URL +`/patient/check-existed-patient`,data,{ headers:{accessToken: Cookies.get('accessToken'),}})
    if(response.data.error){
      dispalyPatientDepartmentError("ไม่พบคนไข้")
    }else{
      handFindPaitientModalCancel();
      setFindPatientResponseData(response.data)
      setIsPatientResponseOpen(true);
    }
  }

  const handlePatientResponseModalCancel= async ()=>{
    setIsPatientResponseOpen(false)
    setFindPatientResponseData();
  }

  const handleRowClick =(record)=>{
    if(record.department_id !== authState.department_id){
      dispalyPatientDepartmentError("ไม่ใช่คนไข้สาขานี้")
    }else{
      navi(`/patient/${record.id}/${authState.department_id}`)
    }
    console.log(record)
  }

  const columns = [
    {
      title: 'รหัสคนไข้',
      dataIndex: 'id',
      key: 'id',
      width:'10%',
    },
    {
        title: 'ชื่อคนไข้',
        dataIndex: 'name',
        key: 'name',
    },
    {
    title: 'อายุ',
    dataIndex: 'age',
    key: 'age',
    width:'5%',
    },
    {
    title: 'เบอร์โทรศัพท์',
    dataIndex: 'call_data',
    key: 'call_data',
    width:'15%',
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'idd',
      key: 'idd',
      width:'15%',
      },
    {
      title: 'วันที่เข้าร่วม',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width:'15%',
      
    },
    {
      title: 'สาขา',
      dataIndex: 'clinic_name',
      key: 'clinic_name',
      width:'15%',
      
    },
  ];

  
  const handleForceCreate=async()=>{
    setLoading(true)
    const values = form.getFieldsValue();
    const newValue = {...values,department_id:authState.department_id}
    const response =  await axios.post(process.env.REACT_APP_API_URL +`/patient/force-create`,newValue,{ headers:{accessToken: Cookies.get('accessToken'),}})
    if(response.data.error)
      {
        dispalyPatientDepartmentError('มีบางอย่างผิดพลาด')
        setLoading(false);
        navi(`/`)
        return;
      }
    navi(`/patient/${response.data.id}/${authState.department_id}`)
    }
  
  const handleTempCancel=async()=>{
    setIsTempOpen(false)
    setFindPatientResponseData();

  }




    const tableCSS = css({
      backgroundColor: 'white',
      '& table': {
        borderCollapse: 'collapse'
      },
      '& thead > tr': {
        borderWidth: '1px',
        borderColor: 'black',
        borderStyle: 'solid',
        backgroundColor: "blue"
      },
      '.ant-table-thead .ant-table-cell':
      {
        backgroundColor: '#00BA9E',
        color: 'white',
        borderRadius: 'unset !important'
      },
      '& .ant-table-tbody > tr > td:hover': {
        cursor: 'pointer'
      }
    });

  
  
  return (
    <>
    <div >
        <div className='flex justify-center items-end m-5'>
          <img src={patietnLogo} alt="logo" className='md:w-[5rem]'/>
          <h1 className='md:text-[3rem] text-[2rem] mb-0 leading-1 h-[3rem]'>รายชื่อคนไข้</h1>
        </div>
    <PatientListcom/>
    
    <Button className='bg-white m-5 ' disabled={authState.department_id === 0} onClick={()=>{setIsmodalopen(true)}}>เพิ่มคนไข้</Button>
    <Button className='bg-white m-5 ' disabled={authState.department_id === 0} onClick={()=>{setIsFindPatientModalOpen(true)}}>ค้นหาคนไข้</Button>
  
    </div>


    <Modal title={"เพิ่มคนไข้"}open={Ismodalopen} onCancel={handModalCancel}footer={[]} width={1000} >
      <Form
    form={form}
    name="validateOnly"
    layout="horizontal"
    autoComplete="off"
    labelCol={{ span: 4 }}
  >
    <Form.Item
      name="name"
      label="ชื่อคนไข้"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกชื่อคนไข้',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="age"
      label="อายุ"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกอายุ',
        },
        {
          pattern: /^[0-9]+$/,
          message: 'อายุต้องอยู่ในช่วง 0-100 ปี',
        },
      ]}
    > 
      <Input />
    </Form.Item>
    <Form.Item
      name="HN"
      label="เลขHN"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกเลข HN',
        },
        {
          pattern: /^[0-9]+$/,
          message: 'กรุณากรอกเลข HN เป็นตัวเลขเท่านั้น',
        },
      ]}
    >
      <Input />
    </Form.Item>

    
    
    <Form.Item
      name="idd"
      label="หมายเลขบัตรประชาชน"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกหมายเลขบัตรประชาชน',
        },
        {
          pattern: /^[0-9]+$/,
          message: 'กรุณากรอกหมายเลขบัตรประชาชนเป็นตัวเลขเท่านั้น',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="Address"
      label="ที่อยู่"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกที่อยู่',
        },
      ]}
    >
      <TextArea />
    </Form.Item>
    <Form.Item
      name="email"
      label="Email"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกอีเมล',
        },
        {
          validator: async (_, value) => {
            if (value === "-" || (value && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('รูปแบบอีเมลไม่ถูกต้อง'));
          },
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="call_data"
      label="เบอร์โทรศัพท์"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกเบอร์โทรศัพท์',
        },
        {
          pattern: /^[0-9]+$/,
          message: 'กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลขเท่านั้น',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="treatment_history"
      label="ประวัติการรักษา"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกประวัติการรักษา',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="chronic_Disease"
      label="โรคประจำตัว"
      rules={[
        {
          required: true,
          message: 'กรุณากรอกโรคประจำตัว',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item 
          label="รู้จักจาก"
          name="known_form"
          rules={[
            {
              required: true,
              message: 'กรุณาเลือกประเภท',
            },
          ]}
        >
          <Radio.Group onChange={(e) => setSelectedAds(e.target.value)}>
            <Radio value="Facebook"> Facebook </Radio>
            <Radio value="Google"> Google </Radio>
            <Radio value="Youtube"> Youtube </Radio>
            <Radio value="Tiktok"> Tiktok </Radio>
            <Radio value="ป้ายโฆษณา"> ป้ายโฆษณา </Radio>
            <Radio value="บอกต่อ"> บอกต่อ </Radio>
            <div className='flex items-center'>
              <Radio value="อื่นๆ"> อื่นๆ </Radio>
              {selectedAds === 'อื่นๆ' && (
                  <Form.Item style={{ marginBottom: "0px" }}
                    name="known_form_other"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกข้อมูล',
                      },
                    ]}
                  >
                    <Input  />
                  </Form.Item>
              )}
            </div>
          </Radio.Group>
        </Form.Item>

    <Form.Item>
      <Space>
        <SubmitButton form={form} authState={authState} handModalCancel={handModalCancel} setLoading={setLoading} setIsTempOpen={setIsTempOpen} setFindPatientResponseData={setFindPatientResponseData} />
        <Button htmlType="reset" onClick={handModalCancel}>ยกเลิก</Button>
      </Space>
    </Form.Item>
      </Form>

    </Modal>
    <Modal title={"ค้นหาคนไข้"} open= {isFindPatientModalOpen} onCancel={handFindPaitientModalCancel} footer={[]} width={500}>
        <Form
        form={findPatientForm}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        labelCol={{ span: 4 }}
        onFinish={handFindPaitientModalFinish}
      >
        <Form.Item
          name="type"
          label="ประเภท"
          rules={[
            {
              required: true,
              message: 'กรุณาเลือกประเภท',
            },
          ]}
        >
          <Select placeholder='กรุณาเลือกประเภท' options={finderOptions}/>
        </Form.Item>
        <Form.Item
          name="value"
          label=""
          rules={[
            {
              required: true,
              message: 'กรุณาเติมช่องว่าง',
            },
          ]}
        > 
          <Input placeholder='กรุณากรอก ชื่อ เบอร์โทรศัพท์ หรือ เลขบัตรประชาชน'/>
      </Form.Item>
      <Button htmlType="submit" >
                ค้นหา
      </Button>
      <Button htmlType="reset" onClick={handFindPaitientModalCancel} >
                ปิด
      </Button >
      </Form>
    </Modal>
    
    <Modal title={"รายการคนไข้ที่พบ"} open={isPatientResponseOpen} onCancel={handlePatientResponseModalCancel} footer={[]} width={1000}>
      <Table columns={columns} 
      dataSource={findPatientResponseData} 
      onRow={(record, rowIndex) => {
          return {
          onClick: event => {handleRowClick(record)}, 
      }}}
      className={tableCSS}
      size={"small"}
      scroll={{x:'max-content'}}
          >
    </Table>
          
    </Modal>

    <Modal title={"ข้อมูลคนไข้ซ้ำ"} open={isTempOpen} onCancel={handleTempCancel} footer={[]} width={800} >
      <Table columns={columns} 
        dataSource={findPatientResponseData} 
        onRow={(record, rowIndex) => {
            return {
            onClick: event => {handleRowClick(record)}, 
        }}}
        className={tableCSS}
        size={"small"}
        scroll={{x:'max-content'}}
            >
      </Table>

      <div className='flex flex-1  justify-center items-center'>
        <div className='flex flex-col gap-5'>
        <Button onClick={handleForceCreate}>ยืนยันที่จะเพิ่มคนไข้</Button>
        <Button onClick={handleTempCancel}>ยกเลิก</Button>
        </div>
      </div>
    </Modal>
    {contextHolder}
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
    <CircularProgress color="inherit" />
    </Backdrop>
    </>
  )
}
export default PatientList