import React from 'react';
import { Descriptions} from 'antd';



const EmployeeDetail = ({selectedEmployee}) => {

    const items = [
        {
          key: '1',
          label: 'รหัสพนักงาน : ',
          children: selectedEmployee.id,
        },
        {
          key: '2',
          label: 'ชื่อพนักงาน : ',
          children: selectedEmployee.name,
        },
        {
          key: '3',
          label: 'ชื่อเล่น : ',
          children: selectedEmployee.nickname,
        },
        {
          key: '4',
          label: 'อายุ : ',
          children: selectedEmployee.age,
        },
        {
          key: '5',
          label: 'ตำแหน่ง : ',
          children: selectedEmployee.role,
        },
        {
          key: '6',
          label: 'สถานะพนักงาน : ',
          children: selectedEmployee.status,
        },

      ];


  return (

    <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[1rem] my-10 rounded-3xl' >
          <div className='p-[24px] bg-white shadow-2xl rounded-3xl'>
        <Descriptions
        title="ข้อมูลพนักงาน"
        bordered
        column={{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        items={items}
    />
    </div>
    </div>
  )
}

export default EmployeeDetail