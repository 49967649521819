import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import '../pages/patient.css';

import CourseDetail from './CourseDetail';
import CourseHistory from './CourseHistory';
import Billhistory from './Billhistory';


import Cookies from 'js-cookie';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab, tabClasses } from '@mui/base/Tab';



const Tabss = () => {
    let {id} = useParams()
    const [isLoading, setIsloading] = useState(false)
    const [patient, setPatient] = useState({})

    useEffect(() => {
      axios.get(process.env.REACT_APP_API_URL + `/patient/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}}).then((response) => {
        setPatient(response.data);
      setIsloading(false)
      });
    }, []);



    const blue = {
      50: '#F0F7FF',
      100: '#C2E0FF',
      200: '#80BFFF',
      300: '#66B2FF',
      400: '#3399FF',
      500: '#007FFF',
      600: '#0072E5',
      700: '#0059B2',
      800: '#004C99',
      900: '#003A75',
    };
    
    const grey = {
      50: '#f6f8fa',
      100: '#eaeef2',
      200: '#d0d7de',
      300: '#afb8c1',
      400: '#8c959f',
      500: '#6e7781',
      600: '#57606a',
      700: '#424a53',
      800: '#32383f',
      900: '#24292f',
    };
    
    const StyledTab = styled(Tab)`
      font-family: 'IBM Plex Sans', sans-serif;
      color: #fff;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 600;
      background-color: transparent;
      width: 100%;
      padding: 10px 12px;
      margin: 6px;
      border: none;
      border-radius: 7px;
      display: flex;
      justify-content: center;
    
      &:hover {
        background-color: #44e381;
      }
    
    
      &.${tabClasses.selected} {
        background-color: #ffffff;
        color: #000000;
      }
    
      &.${buttonClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `;
    
    const StyledTabPanel = styled(TabPanel)(
      ({ theme }) => `
      `,
    );
    
    const StyledTabsList = styled(TabsList)(
      ({ theme }) => `
      min-width: 300px;
      background-color: #C95252;
      border-radius: 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: space-between;
      box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
      `,
    );

  return (
    <>
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
    <CircularProgress color="inherit" />
    </Backdrop>

      <div >
      <Tabs defaultValue={0}>
      <StyledTabsList className='w-[50%] mx-auto mt-3'>
        <StyledTab value={0}>รายการ</StyledTab>
        <StyledTab value={1}>ประวัติ</StyledTab>
        <StyledTab value={2}>ใบเสร็จ</StyledTab>
      </StyledTabsList>
      <StyledTabPanel value={0}><CourseDetail patient_data={patient} /></StyledTabPanel>
      <StyledTabPanel value={1}> <CourseHistory  patient_data={patient}/> </StyledTabPanel>
      <StyledTabPanel value={2}><Billhistory patient_data={patient} setPatient_data={setPatient}/></StyledTabPanel>
      </Tabs>

      </div>


    </>
  )
}

export default Tabss