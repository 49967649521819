import React from 'react'
import { useNavigate } from 'react-router-dom'


import { Button } from 'antd'
import {TeamOutlined ,ShopOutlined ,ShoppingCartOutlined ,UserAddOutlined } from '@ant-design/icons';



const Adminpage = () => {
  let nv = useNavigate();
  return (
    <div className='  flex  items-center mt-10  flex-col'>
        <div className='flex'> 
          <Button className=' bg-[#4BBA7D] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]' onClick={()=>{nv('/admin/employee')}}><><TeamOutlined className=' md:text-[100px] text-[#000000] text-[3.5rem]'/><h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white'>จัดการพนักงาน</h1></> </Button>
          <Button className=' bg-[#4bba63] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]' onClick={()=>{nv('/admin/department')}}> <><ShopOutlined className=' md:text-[100px] text-[#000000] text-[3.5rem]'/><h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white'>จัดการสาขา</h1></></Button>
        </div>
        <div className='flex'> 
        <Button className=' bg-[#67dd4f] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]' onClick={()=>{nv('/admin/service')}}> <> <ShoppingCartOutlined className=' md:text-[100px] text-[#000000] text-[3.5rem]'/> <h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white whitespace-normal'>จัดการสินค้า-บริการ</h1>  </></Button>
        <Button className=' bg-[#d4dd4f] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2 hover:opacity-80 rounded-3xl hover:translate-y-[-5px]' onClick={()=>{nv('/admin/register')}}> <> <UserAddOutlined className=' md:text-[100px] text-[#000000] text-[3.5rem]'/> <h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white whitespace-normal'>ลงทะเบียนบัญชี</h1>  </></Button>
        </div>
        {/* <Button className=' bg-[#90918f] md:w-[20rem] md:h-[20rem] w-[10rem] h-[10rem] m-2  rounded-3xl cursor-not-allowed ' > <><ToolOutlined className=' md:text-[100px] text-[#000000] text-[3.5rem]'/><h1 className='md:text-3xl text-[1rem] font-bold md:pt-5 text-white whitespace-normal'>อยู่ระหว่างดำเนินการ</h1></></Button> */}
    </div>
  )
}

export default Adminpage